import type { ThemeMode } from '@web-solutions/core/interfaces/theme';
import type { QuizStep } from '@web-solutions/base-app/screens/quiz/interfaces';

import { QuizStepKind, QuizStepView } from '@web-solutions/core/containers/questions/types';

import { QUIZ } from 'src/constants/routes';
import { TOP_SIZES, BOTTOM_SIZES } from 'src/screens/quiz/anthropometric/components/size-link-modal/constants';
import { EYES_COLORS, HAIR_COLORS, SKIN_TONE } from 'src/screens/subscription/sections/personal-summary/constants';

import { SizeLinkModal } from 'src/screens/quiz/anthropometric/components/size-link-modal';

import { IMAGES } from './images';

export const STEPS: Record<string, QuizStep> = {
  WARDROBE_PEEK_IN_CLOSET: {
    type: 'multi',
    title: 'Take a peek in your closet. What do you see?',
    options: {
      "basics": "Mostly basics and neutrals",
      "prints": "Lots of prints and patterns",
      "colorful": "Lots of colorful pieces",
      "trendy": "Trendy pieces",
      "vintage": "Vintage pieces",
      "similar": "Lots of similar pieces",
      "never_wear": "Pieces I never wear",
      "really_love": "Pieces I really love",
    },
  },
  WARDROBE_MOST_OFTEN_COLORS: {
    type: 'multi',
    title: 'What are the colors you wear most often?',
    options: {
      "warm": "Warm tones",
      "cool": "Cool tones",
      "neutrals": "Neutrals",
      "pastels": "Pastels",
      "earthy": "Earthy tones",
      "jewel": "Jewel tones",
      "bright": "Bright tones",
    },
  },
  WARDROBE_FAVORITE_FABRIC: {
    type: 'multi',
    title: 'What are your favorite types of fabric?',
    options: {
      "light": "Light and airy",
      "soft": "Soft and cozy",
      "smooth": "Smooth and silky",
      "warm": "Warm and fuzzy",
      "durable": "Durable and sturdy",
      "stretchy": "Stretchy and comfy",
    },
  },
  WARDROBE_AVOID_COLORS: {
    type: 'multi',
    title: 'What are the colors you tend to avoid?',
    options: {
      "warm": "Warm tones",
      "cool": "Cool tones",
      "neutrals": "Neutrals",
      "pastels": "Pastels",
      "earthy": "Earthy tones",
      "jewel": "Jewel tones",
      "bright": "Bright tones",
    },
  },
  WARDROBE_WEAR_REGULARLY: {
    type: 'single',
    title: 'How much of your current wardrobe do you wear regularly?',
    options: {
      "10": "10%",
      "30": "30%",
      "50": "50%",
      "70": "70%",
      "90": "90%",
    },
  },
  WARDROBE_FEEL_ABOUT_WARDROBE: {
    type: 'single',
    title: 'How do you feel about your current wardrobe?',
    options: {
      "like_to_refresh": "I'd like to refresh my wardrobe with some new pieces",
      "happy_with_wardrobe": "I'm happy with my wardrobe, but I struggle to put together outfits",
      "love_clothes": "I love my clothes, but I'm bored of wearing the same things",
      "few_items": "I only like a few items in my wardrobe",
      "dont_like": "I don’t like my clothes at all",
    },
  },
  WARDROBE_SPEND_TIME_DAILY: {
    type: 'single',
    kind: QuizStepKind.Numeric,
    uptitle: 'Does the statement below characterize you?',
    title: 'I spend too much time daily deciding what outfit to pick',
  },
  WARDROBE_OWN_A_LOT_OF_LOTHES: {
    type: 'single',
    kind: QuizStepKind.Numeric,
    uptitle: 'Does the statement below characterize you?',
    title: 'I own a lot of clothes but don’t know how to style them',
  },
  WARDROBE_STRUGGLE_TO_FIND_CLOTHES: {
    type: 'single',
    kind: QuizStepKind.Numeric,
    uptitle: 'Does the statement below characterize you?',
    title: 'I struggle to find clothes that suit my body shape',
  },
  WARDROBE_DRESS_APPROPRIATEL_: {
    type: 'single',
    kind: QuizStepKind.Numeric,
    uptitle: 'Does the statement below characterize you?',
    title: 'I find it hard to dress appropriately to my age, occasion, or weather',
  },
  WARDROBE_HOW_OLD: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'How old are you?',
    options: {
      "18_24": "18-24",
      "25_31": "25-31",
      "32_38": "32-38",
      "39_45": "39-45",
      "46_50": "46-50+",
    },
    images: {
      "18_24": IMAGES.OLD_18_24,
      "25_31": IMAGES.OLD_25_31,
      "32_38": IMAGES.OLD_32_38,
      "39_45": IMAGES.OLD_39_45,
      "46_50": IMAGES.OLD_46_50,
    },
  },
  WARDROBE_DRESS_FOR_OCCASIONS: {
    type: 'multi',
    kind: QuizStepKind.Square,
    title: 'What occasions do you usually dress for?',
    options: {
      "work": "Work",
      "date_nights": "Date nights",
      "casual": "Casual outings",
      "events": "Special events",
      "active": "Active vacation",
      "beach": "Beach vacation",
      "workouts": "Workouts",
      "other": "Other",
    },
    images: {
      "work": IMAGES.WORK_LIGHT,
      "date_nights": IMAGES.DATE_LIGHT,
      "casual": IMAGES.CASUAL_LIGHT,
      "events": IMAGES.SPECIAL_EVENTS_LIGHT,
      "active": IMAGES.ACTIVE_VACATION,
      "beach": IMAGES.VACATION_LIGHT,
      "workouts": IMAGES.WORKOUTS,
      "other": IMAGES.OTHER_LIGHT,
    },
  },
  WARDROBE_TYPICAL_WEATHER: {
    type: 'multi',
    kind: QuizStepKind.Square,
    title: 'What’s the typical weather where you live or travel?',
    options: {
      "warm": "Warm",
      "cold": "Cold",
      "rainy": "Rainy",
      "windy": "Windy",
      "changing": "Ever-changing",
      "mix": "Seasonal mix",
      "humid": "Humid",
    },
    images: {
      "warm": IMAGES.WARM,
      "cold": IMAGES.COLD,
      "rainy": IMAGES.RAINY,
      "windy": IMAGES.WINDY,
      "changing": IMAGES.EVER_CHANGING,
      "mix": IMAGES.SEASONAL_MIX,
      "humid": IMAGES.HUMID,
    },
  },
  WARDROBE_USUALLY_SHOP_CLOTHES: {
    type: 'single',
    title: 'How do you usually shop for clothes?',
    options: {
      "online": "I browse online for inspiration",
      "stores": "I check out physical stores",
      "list": "I make a list of items I need",
      "sales": "I wait for sales and deals",
      "feel_shop": "I shop when I feel like it",
      "rarely": "I rarely buy clothes for myself",
    },
  },
  WARDROBE_PREFER_BRANDS: {
    type: 'multi',
    title: 'Which fashion brands do you prefer?',
    options: {
      "street": "High-street brands",
      "sportswear": "Sportswear brands",
      "luxury": "Luxury brands",
      "local": "Local brands",
      "none": "None of the above",
    },
  },
  WARDROBE_BUDGET_FOR_TOPS: {
    type: 'single',
    title: 'What\'s your typical budget for basic tops?',
    options: {
      "under_20": "Under $20 per item",
      "20_50": "$20 – $50",
      "50_150": "$50 – $150",
      "150_300": "$150 – $300",
      "more_300": "$300+",
    },
  },
  WARDROBE_BUDGET_FOR_BOTTOMS: {
    type: 'single',
    title: 'What\'s your typical budget for basic bottoms?',
    options: {
      "under_20": "Under $20 per item",
      "20_50": "$20 – $50",
      "50_150": "$50 – $150",
      "150_300": "$150 – $300",
      "more_300": "$300+",
    },
  },
  WARDROBE_FRUSTRATES_WHEN_SHOP: {
    type: 'single',
    title: 'What frustrates you the most when you shop for clothes?',
    options: {
      "no_time": "I don't have time for shopping",
      "overwhelmed": "I’m overwhelmed by choices",
      "right_fit": "I struggle to find the right fit and size",
      "find_quality": "I struggle to find good-quality pieces",
      "feel_pressured": "I feel pressured to keep up with trends",
      "cant_afford": "I can’t afford clothes I like",
      "none": "None of the above",
    },
  },
  WARDROBE_NEVER_WEAR: {
    type: 'single',
    title: 'Do you often buy clothes you never wear?',
    options: {
      "no_time": "Hardly ever",
      "overwhelmed": "Once in a while",
      "right_fit": "Most of the time",
      "find_quality": "Way too often",
    },
  },
  WARDROBE_OUTFIT_FORMULA: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'What\'s your go-to outfit formula?',
    options: {
      "hoodie": "Hoodie and jeans",
      "shirt": "Shirt and pants",
      "blouse": "Blouse and skirt",
      "sweater": "Sweater and leggings",
      "sweatshirt": "Sweatshirt and sweatpants",
      "dress": "Dress",
      "else": "Something else",
    },
    images: {
      "hoodie": IMAGES.HOODIE_AND_JEANS,
      "shirt": IMAGES.SHIRT_AND_PANTS,
      "blouse": IMAGES.BLOUSE_AND_SKIRT,
      "sweater": IMAGES.SWEATER_AND_LEGGINGS,
      "sweatshirt": IMAGES.SWEATSHIRT_AND_SWEATPANTS,
      "dress": IMAGES.DRESS,
      "else": IMAGES.OTHER_LIGHT,
    },
  },
  WARDROBE_ACCESSORIES: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'What kind of accessories do you usually buy?',
    options: {
      "jewelry": "Jewelry",
      "eyewear": "Eyewear",
      "headwear": "Headwear",
      "belts": "Belts",
      "bags": "Bags",
      "gloves": "Gloves",
      "scarves": "Scarves",
      "other": "Other",
    },
    images: {
      "jewelry": IMAGES.JEWELRY,
      "eyewear": IMAGES.EYEWEAR,
      "headwear": IMAGES.HEADWEAR,
      "belts": IMAGES.BELTS,
      "bags": IMAGES.BAGS,
      "gloves": IMAGES.GLOVES,
      "scarves": IMAGES.SCARVES,
      "other": IMAGES.OTHER_LIGHT,
    },
  },
  WARDROBE_MISSING_PIECES: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'What are the pieces missing from your closet?',
    options: {
      "basics": "Everyday basics",
      "seasonal": "Seasonal wear",
      "formal": "Formal attire",
      "Accessories": "Accessories",
      "dressy": "Dressy pieces",
      "athleisure": "Athleisure",
      "else": "Something else",
      "all_have": "I have everything I need",
    },
    images: {
      "basics": IMAGES.EVERYDAY_BASICS,
      "seasonal": IMAGES.SEASONAL_WEAR,
      "formal": IMAGES.FORMAL_ATTIRE,
      "Accessories": IMAGES.ACCESSORIES,
      "dressy": IMAGES.DRESSY_PIECES,
      "athleisure": IMAGES.ATHLEISURE,
      "else": IMAGES.OTHER_LIGHT,
      "all_have": IMAGES.HAVE_EVERYTHING,
    },
  },
  WARDROBE_GOAL_WHEN_GETTING_DRESSED: {
    type: 'single',
    title: 'What’s your main goal when getting dressed in the morning?',
    options: {
      "beautiful": "Looking beautiful",
      "confident": "Feeling confident",
      "comfortable": "Feeling comfortable",
      "professional": "Looking professional",
      "mood": "Expressing my mood",
      "personality": "Expressing my personality",
      "get_through": "Just getting through the day",
    },
  },
  WARDROBE_CLOTHES_SAY: {
    type: 'single',
    title: 'What do you want your clothes to say about you?',
    options: {
      "professional": "Professional and confident",
      "powerful": "Powerful and assertive",
      "young": "Young and vibrant",
      "bold": "Bold and playful",
      "None": "None of the above",
    },
  },
  WARDROBE_INSPIRES_STYLE: {
    type: 'multi',
    title: 'What inspires your style?',
    options: {
      "influencers": "Fashion influencers",
      "friends": "Friends and family",
      "celebrities": "Celebrities",
      "magazines": "Magazines",
      "creativity": "My own creativity",
      "else": "Something else",
    },
  },
  WARDROBE_OUTFIT_THAT_FLATTERS: {
    type: 'single',
    title: 'How do you usually feel when you wear an outfit that flatters you?',
    options: {
      "confidence": "Sparkling with confidence",
      "comfortable": "Comfortable and at ease",
      "fashion_icon": "Like a fashion icon",
      "unstoppable": "Totally unstoppable",
      "next_level": "Next-level beautiful",
      "all": "All of the above",
      "none": "None of the above",
    },
  },
  WARDROBE_STYLE_STRUGGLE: {
    type: 'single',
    title: 'What\'s your biggest style struggle?',
    options: {
      "color_palette": "Choosing the right color palette",
      "matching": "Matching prints and patterns",
      "body_shape": "Dressing up my body shape",
      "trends": "Keeping up with trends",
      "appropriately": "Dressing appropriately",
      "layering": "Layering clothes",
      "accessorizing": "Accessorizing",
      "none": "None of the above",
    },
  },
  WARDROBE_HOW_CLOTHES_FIT: {
    type: 'multi',
    kind: QuizStepKind.Square,
    title: 'How do you like your clothes to fit?',
    options: {
      "tight": "Tight",
      "loose": "Loose",
      "regular": "Regular",
      "oversized": "Oversized",
      "tailored": "Tailored",
    },
    images: {
      "tight": IMAGES.TIGHT,
      "loose": IMAGES.LOOSE,
      "regular": IMAGES.REGULAR,
      "oversized": IMAGES.OVERSIZED,
      "tailored": IMAGES.TAILORED,
    },
  },
  WARDROBE_FAVORITE_FEATURE: {
    type: 'multi',
    kind: QuizStepKind.Square,
    title: 'What\'s your favorite feature to highlight?',
    options: {
      "legs": "My legs",
      "waist": "My waist",
      "bust": "My bust",
      "arms": "My arms",
      "neck": "My neck",
      "shoulders": "My shoulders",
      "else": "Something else",
    },
    images: {
      "legs": IMAGES.MY_LEGS,
      "waist": IMAGES.MY_WAIST,
      "bust": IMAGES.MY_BUST,
      "arms": IMAGES.MY_ARMS,
      "neck": IMAGES.MY_NECK,
      "shoulders": IMAGES.MY_SHOULDERS,
      "else": IMAGES.OTHER_LIGHT,
    },
  },
  WARDROBE_CORRECT_SILHOUETTE: {
    type: 'multi',
    title: 'Is there anything you want to correct in your silhouette?',
    options: {
      "slimmer": "I want to appear slimmer",
      "curves": "I want to define my curves",
      "taller": "I want to appear taller",
      "shorter": "I want to appear shorter",
      "balance": "I want to balance my top and bottom",
      "happy": "I am happy with my silhouette",
    },
  },

  [QUIZ.TRANSITION_WORK_FOR_YOU]: {
    type: 'transition',
    title: 'Know which colors and styles work for you',
    subTitle: 'Answer a few questions about your looks and fashion goals to let our AI stylist create a perfect wardrobe for you',
    image: IMAGES.TRANS_WORK_FOR_YOU,
  },
  [QUIZ.TRANSITION_YOU_NOT_ALONE]: {
    type: 'transition',
    title: 'Don’t worry, you’re not alone in this',
    subTitle: 'Millions of women know the struggle, so we’ve created Stylix to empower them to dress with confidence every day',
    image: IMAGES.TRANS_YOU_NOT_ALONE,
  },
  [QUIZ.TRANSITION_CAPSULE_WARDROBE]: {
    type: 'transition',
    title: 'Build a capsule wardrobe you’ll wear for years',
    subTitle: 'Based on your looks and lifestyle needs, our AI stylist will suggest timeless outfits that highlight your unique beauty',
    image: IMAGES.TRANS_CAPSULE_WARDROBE,
  },
  [QUIZ.TRANSITION_LOOK_BEST]: {
    type: 'transition',
    title: 'You deserve to look and feel your best',
    subTitle: 'We’re here to help women choose stylish outfits, look attractive, and always feel comfortable, no matter the occasion',
    image: IMAGES.TRANS_LOOK_BEST,
  },
  [QUIZ.TRANSITION_PERFECT_CUTS]: {
    type: 'transition',
    title: 'Let’s explore your perfect cuts and colors',
    subTitle: 'Our AI stylist will analyze your looks and give you personalized suggestions for clothes, accessories, and makeup',
    image: IMAGES.TRANS_PERFECT_CUTS,
    buttonText: "I'm Ready for Analysis"
  },


  [QUIZ.TRANSITION_WHICH_SUIT]: {
    type: 'transition',
    title: 'Know which colors and styles suit you',
    subTitle: 'A few questions to personalize your guide',
    image: IMAGES.TRANS_WHICH_SUIT,
  },
  [QUIZ.TRANSITION_NOTHING_TO_WEAR]: {
    type: 'transition',
    title: 'Got a wardrobe full of clothes but nothing to wear?',
    subTitle: 'No more hours wasted trying on clothes that just don\'t suit you!',
    description: 'In just a few quick questions, we will help you uncover your personal style and suggest outfits that will make you feel like a million bucks',
    image: IMAGES.TRANS_NOTHING_TO_WEAR,
  },
  [QUIZ.TRANSITION_WARDROBE_REGRETS]: {
    type: 'transition',
    title: 'Say goodbye to wardrobe regrets',
    subTitle: 'Stop wasting money on outfits that don’t enhance your beauty and uniqueness',
    description: 'With our personalized recommendations, you will have a functional wardrobe filled with clothes you love and can\'t wait to wear',
    image: IMAGES.TRANS_WARDROBE_REGRETS,
  },
  [QUIZ.TRANSITION_GAME]: {
    type: 'transition',
    title: 'Ready to revamp your style game?',
    subTitle: 'Answer a few more questions to define your color type and let our AI stylist find the right clothes for you.',
    description: 'You will have a perfect outfit for every occasion and look amazing without spending hours in front of the mirror',
    image: IMAGES.TRANS_GAME,
  },
  [QUIZ.TRANSITION_COLOR_TYPE]: {
    type: 'transition',
    title: 'Let\'s discover your Color Type',
    subTitle: 'We\'ve developed a smart technology to analyze your complexion and facial features.',
    image: IMAGES.TRANS_COLOR_TYPE,
  },
  [QUIZ.TRANSITION_FORMULA]: {
    type: 'transition',
    title: 'Discover your unique style formula',
    subTitle: 'With your custom capsule and color type, dressing your best has never been easier.',
    description: 'Our AI stylist has crafted a personalized wardrobe for you, where every piece perfectly pairs together',
    image: IMAGES.TRANS_FORMULA,
  },
  [QUIZ.TRANSITION_EVERY_OUTFIT]: {
    type: 'transition',
    title: 'Feel confident and comfortable in every outfit',
    subTitle: 'Unlock your personalized style guide now and get ready to feel more beautiful, powerful, and stylish',
    image: IMAGES.TRANS_EVERY_OUTFIT,
  },
  AGE: {
    type: 'single',
    title: 'How old are you?',
    options: {
      "under_18": "Under 18 years",
      "18_29": "18–29 years",
      "30_39": "30–39 years",
      "40_49": "40–49 years",
      "over_50": "50+ years",
    },
  },
  MAIN_GOAL: {
    type: 'single',
    title: 'What is your main goal when getting dressed in the morning?',
    options: {
      "beautiful": "Looking beautiful",
      "confident": "Feeling confident",
      "comfortable": "Feeling comfortable",
      "professional": "Looking professional",
      "personality": "Expressing my personality",
      "through_day": "Just getting through the day",
      "other": "Other",
    },
    images: {
      "beautiful": IMAGES.LOVE,
      "confident": IMAGES.OK,
      "comfortable": IMAGES.CUTE,
      "professional": IMAGES.COOL,
      "personality": IMAGES.PERSONALITY,
      "through_day": IMAGES.THROUGH_DAY,
      "other": IMAGES.OTHER_SMALL,
    },
  },
  OUTFIT_FOR_DAY: {
    type: 'single',
    title: 'How much time do you typically spend choosing your outfit for the day?',
    options: {
      "less_5": "Up to 5 minutes",
      "5_10": "5-10 minutes",
      "10_30": "10-30 minutes",
      "more_30": "More than 30 minutes",
    },
  },
  BIGGEST_CHALLENGES: {
    type: 'multi',
    title: 'What are the biggest challenges you face when it comes to style?',
    options: {
      "outfits": "Mixing and matching outfits",
      "personal_style": "Defining my personal style",
      "body_type": "Dressing for my body type",
      "palette": "Сhoosing a color palette",
      "events": "Dressing up for events",
      "trends": "Keeping up with trends",
      "other": "Other",
    },
    images: {
      "outfits": IMAGES.OUTFITS,
      "personal_style": IMAGES.PERSONAL_STYLE,
      "body_type": IMAGES.BODY_TYPE,
      "palette": IMAGES.PALETTE,
      "events": IMAGES.EVENTS,
      "trends": IMAGES.TRENDS,
      "other": IMAGES.OTHER_SMALL,
    },
  },
  STRUGGLE_PICKING_OUT: {
    type: 'multi',
    title: 'In what situations do you struggle with picking out an outfit?',
    options: {
      "work": "Work events",
      "date": "Date nights",
      "casual": "Casual outings",
      "special": "Special events",
      "vacations": "Vacations",
      "everyday": "Everyday wear",
      "other": "Other",
    },
    images: {
      "work": IMAGES.WORK,
      "date": IMAGES.EVENTS,
      "casual": IMAGES.CASUAL,
      "special": IMAGES.SPECIAL,
      "vacations": IMAGES.VACATIONS,
      "everyday": IMAGES.EVERYDAY,
      "other": IMAGES.OTHER_SMALL,
    },
  },
  MOST_FRUSTRATES: {
    type: 'multi',
    title: 'What frustrates you most when shopping for clothes?',
    options: {
      "size": "Finding the right fit and size",
      "suit": "Finding clothes that suit me",
      "quality": "Finding clothes of good quality",
      "trendy": "Choosing stylish and trendy pieces",
      "together": "Finding pieces that go well together",
      "unneeded": "Wasting money on unneeded clothes",
      "overwhelmed": "Feeling overwhelmed by choices",
      "other": "Other",
    },
  },
  MOST_COMFORTABLE: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'What kind of clothes do you feel most comfortable in?',
    options: {
      "jeans": "Jeans and\nT-shirts",
      "skirts": "Skirts and\nblouses",
      "suits": "Suits and\nblazers",
      "shorts": "Shorts and tank\ntops",
      "leggings": "Leggings and\nsweaters",
      "sweatpants": "Sweatpants and\nhoodies",
      "none": "None of the\nabove",
    },
    images: {
      "jeans": IMAGES.JEANS,
      "skirts": IMAGES.SKIRTS,
      "suits": IMAGES.SUITS,
      "shorts": IMAGES.SHORTS,
      "leggings": IMAGES.LEGGINGS,
      "sweatpants": IMAGES.SWEATPANTS,
      "none": IMAGES.BIG_NONE,
    },
  },
  MOST_RESONATES: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'Which aesthetics resonates with you the most?',
    options: {
      "casual_style": "Casual",
      "glam": "Glam",
      "boho": "Boho",
      "romantic": "Romantic",
      "minimalist": "Minimalist",
      "classic": "Classic",
      "street": "Street",
      "preppy": "Preppy",
      "edgy": "Edgy",
      "sporty": "Sporty",
      "y2k": "Y2K",
      "eclectic": "Eclectic",
    },
    images: {
      "casual_style": IMAGES.CASUAL_STYLE,
      "glam": IMAGES.GLAM,
      "boho": IMAGES.BOHO,
      "romantic": IMAGES.ROMANTIC,
      "minimalist": IMAGES.MINIMALISTIC,
      "classic": IMAGES.CLASSIC,
      "street": IMAGES.STREET,
      "preppy": IMAGES.PREPPY,
      "edgy": IMAGES.EDGY,
      "sporty": IMAGES.SPORTY,
      "y2k": IMAGES.Y2K,
      "eclectic": IMAGES.ECLECTIC,
    },
  },
  OUTFIT_YOU_LOVE: {
    type: 'single',
    title: 'How do you usually feel when you put on an outfit you love?',
    options: {
      "confident": "Confident",
      "comfortable": "Comfortable",
      "stylish": "Stylish",
      "powerful": "Powerful",
      "beautiful": "Beautiful",
      "all": "All of the above",
      "other": "Other"
    },
    images: {
      "confident": IMAGES.OK,
      "comfortable": IMAGES.CUTE,
      "stylish": IMAGES.COOL,
      "powerful": IMAGES.POWERFUL,
      "beautiful": IMAGES.LOVE,
      "all": IMAGES.CUTE,
      "other": IMAGES.OTHER_SMALL,
    },
  },
  FEELING_INSECURE: {
    type: 'single',
    title: 'Do you agree that feeling insecure about your looks makes it harder to succeed both in career and personal life?',
    options: {
      "yes": "Yes",
      "no": "No",
      "not_sure": "Not sure",
    }
  },
  HOW_CONFIDENT: {
    type: 'single',
    kind: QuizStepKind.Numeric,
    title: 'On a scale of 1-5, how confident do you feel in your current outfits?',
  },
  EYES_COLOR: {
    type: 'single',
    title: 'What color are your eyes?',
    options: {
      "brown": "Brown",
      "green": "Green",
      "blue": "Blue",
      "gray": "Gray",
      "different": "Different",
      "other": "Other",
    },
    images: {
      "different": IMAGES.DIFFERENT,
      "other": IMAGES.OTHER_SMALL,
    },
    colors: {
      "brown": "#936D4B",
      "green": "#71C07E",
      "blue": "#5BC0F9",
      "gray": "#DEDEDE",
    }
  },
  HAIR_COLOR: {
    type: 'single',
    title: 'What is your hair color?',
    options: {
      "blonde": "Blonde",
      "black": "Black",
      "brown": "Brown",
      "red": "Red",
      "gray": "Gray",
      "other": "Other",
    },
    images: {
      "other": IMAGES.OTHER_SMALL,
    },
    colors: {
      "blonde": "#FFE5A1",
      "black": "#2E2E2E",
      "brown": "#936D4B",
      "red": "#EE5E4B",
      "gray": "#DEDEDE",
    }
  },
  SKIN_TONE: {
    type: 'single',
    title: 'What is your skin tone?',
    options: {
      "fair": "Fair",
      "ivory": "Ivory",
      "sand": "Sand",
      "porcelain": "Porcelain",
      "sienna": "Sienna",
      "band": "Band",
      "umber": "Umber",
      "espresso": "Espresso",
      "other": "Other",
    },
    images: {
      "other": IMAGES.OTHER_SMALL,
    },
    colors: {
      "fair": "#FFE6D5",
      "ivory": "#E2CCAB",
      "sand": "#E6C899",
      "porcelain": "#F1D9BE",
      "sienna": "#C69F7F",
      "band": "#A58B68",
      "umber": "#A46B4D",
      "espresso": "#593B1B",
    }
  },
  CLOTHES_SAY: {
    type: 'single',
    title: 'What do you want your clothes to say about you?',
    options: {
      "professional": "Professional and confident",
      "powerful": "Powerful and successful",
      "young": "Young and vibrant",
      "chic": "Effortlessly chic",
      "fun": "Fun and playful",
      "none": "None of the above",
    },
  },
  WARDROBE: {
    type: 'multi',
    title: 'What do you hope to achieve with your new personalized wardrobe?',
    options: {
      "boost": "Self-confidence boost",
      "budget": "Savings on my budget",
      "shopping": "Stress-free shopping",
      "morning": "More time in the morning",
      "impressions": "Better first impressions",
      "style": "A unique personal style",
      "other": "Other",
    },
    images: {
      "boost": IMAGES.OK,
      "budget": IMAGES.BUDGET,
      "shopping": IMAGES.SHOPPING,
      "morning": IMAGES.MORNING,
      "impressions": IMAGES.LOVE,
      "style": IMAGES.STYLE,
      "other": IMAGES.OTHER_SMALL,
    },
  },
  AI_STYLIST: {
    type: 'multi',
    title: 'What are you most excited to get from our AI stylist?',
    options: {
      "analysis": "Color analysis",
      "wardrobe": "A capsule wardrobe",
      "suggestions": "Outfit suggestions",
      "recommendations": "Style recommendations",
      "tips": "Tips to boost confidence",
      "inspiration": "Fashion inspiration",
      "advice": "Shopping advice",
      "other": "Other",
    },
  }
}

export const STEPS_LIGHT: Record<string, QuizStep> = {
  [QUIZ.MAIN_GOAL]: {
    type: 'multi',
    title: 'What is your main goal when getting dressed in the morning?',
    options: {
      "beautiful": "Looking beautiful",
      "confident": "Feeling confident",
      "comfortable": "Feeling comfortable",
      "professional": "Looking professional",
      "personality": "Expressing my personality",
      "through_day": "Just getting through the day",
      "other": "Other",
    }
  },
  [QUIZ.FLATTER_COLORS]: {
    type: 'single',
    title: 'Do you know which colors and styles flatter you?',
    options: {
      "yes": "Yes",
      "no": "No",
      "50_50": "50/50",
    },
  },
  [QUIZ.FEEL_CONFIDENT]: {
    type: 'single',
    title: 'Do you feel confident in your current outfits?',
    options: {
      "yes": "Yes",
      "no": "No",
      "50_50": "50/50",
    },
  },
  [QUIZ.CURRENT_WARDROBE]: {
    type: 'single',
    title: 'How much of your current wardrobe do you wear regularly?',
    options: {
      "10": "10%",
      "25": "25%",
      "50": "50%",
      "75": "75%",
      "more_90": "More than 90%",
    },
  },
  [QUIZ.FEEL_ANXIOUS]: {
    type: 'single',
    title: 'Do you often feel anxious about what outfit to choose?',
    options: {
      "yes": "Yes, it's a daily struggle",
      "depends_occasion": "It depends on the occasion",
      "usually_trust": "I usually trust my sense of style",
      "no": "It doesn’t bother me much",
    },
  },
  [QUIZ.STRUGGLE_PICKING_OUT]: {
    type: 'multi',
    kind: QuizStepKind.Square,
    title: 'In what situations do you struggle with picking out an outfit?',
    options: {
      "work": "Work events",
      "date": "Date nights",
      "casual": "Casual outings",
      "special": "Special events",
      "vacations": "Vacations",
      "everyday": "Everyday wear",
      "other": "Other",
    },
    images: {
      "work": IMAGES.WORK_LIGHT,
      "date": IMAGES.DATE_LIGHT,
      "casual": IMAGES.CASUAL_LIGHT,
      "special": IMAGES.SPECIAL_EVENTS_LIGHT,
      "vacations": IMAGES.VACATION_LIGHT,
      "everyday": IMAGES.EVERY_DAY_LIGHT,
      "other": IMAGES.OTHER_LIGHT,
    },
  },
  [QUIZ.MOST_FRUSTRATES]: {
    type: 'multi',
    title: 'What frustrates you most when shopping for clothes?',
    options: {
      "size": "Finding the right fit and size",
      "suit": "Finding clothes that suit me",
      "quality": "Finding clothes of good quality",
      "trendy": "Choosing stylish and trendy pieces",
      "together": "Finding pieces that go well together",
      "unneeded": "Wasting money on unneeded clothes",
      "overwhelmed": "Feeling overwhelmed by choices",
      "other": "Other",
    },
  },
  [QUIZ.WASTE_MONEY]: {
    type: 'single',
    title: 'Do you often waste money on clothes you never wear?',
    options: {
      "yes": "All the time",
      "often": "Pretty often",
      "few_times": "It's been a few times",
      "no": "Not really",
    },
  },
  [QUIZ.MOST_COMFORTABLE]: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'What kind of clothes do you feel most comfortable in?',
    options: {
      "jeans": "Jeans and\nT-shirts",
      "skirts": "Skirts and\nblouses",
      "suits": "Suits and\nblazers",
      "shorts": "Shorts and tank\ntops",
      "leggings": "Leggings and\nsweaters",
      "sweatpants": "Sweatpants and\nhoodies",
      "dresses": "Dresses",
      "none": "None of the\nabove",
    },
    images: {
      "jeans": IMAGES.JEANS_LIGHT,
      "skirts": IMAGES.SKIRTS_LIGHT,
      "suits": IMAGES.SUITS_LIGHT,
      "shorts": IMAGES.SHORTS_LIGHT,
      "leggings": IMAGES.LEGGINGS_LIGHT,
      "sweatpants": IMAGES.SWEATPANTS_LIGHT,
      "dresses": IMAGES.DRESSES_LIGHT,
      "none": IMAGES.NONE_LIGHT,
    },
  },
  [QUIZ.MOST_RESONATES]: {
    type: 'single',
    kind: QuizStepKind.Square,
    view: QuizStepView.CAPTION,
    title: 'What style resonates with you the most?',
    options: {
      "casual_style": "Casual",
      "glam": "Glam",
      "boho": "Boho",
      "romantic": "Romantic",
      "minimalist": "Minimalist",
      "classic": "Classic",
      "street": "Street",
      "preppy": "Preppy",
      "edgy": "Edgy",
      "sporty": "Sporty",
      "y2k": "Y2K",
      "eclectic": "Eclectic",
    },
    images: {
      "casual_style": IMAGES.CASUAL_STYLE,
      "glam": IMAGES.GLAM,
      "boho": IMAGES.BOHO,
      "romantic": IMAGES.ROMANTIC,
      "minimalist": IMAGES.MINIMALISTIC,
      "classic": IMAGES.CLASSIC,
      "street": IMAGES.STREET,
      "preppy": IMAGES.PREPPY,
      "edgy": IMAGES.EDGY,
      "sporty": IMAGES.SPORTY,
      "y2k": IMAGES.Y2K,
      "eclectic": IMAGES.ECLECTIC,
    },
  },
  [QUIZ.WEAR_OUTFIT]: {
    type: 'multi',
    title: 'How do you usually feel when you wear an outfit that flatters you?',
    options: {
      "sparkling": "Sparkling with confidence",
      "comfortable": "Comfortable and at ease",
      "icon": " Like a fashion icon",
      "unstoppable": "Totally unstoppable",
      "next_levet": "Next-level beautiful",
      "all_above": "All of the above",
      "other": "Other"
    },
  },
  [QUIZ.BEAUTY_INSECURITIES]: {
    type: 'single',
    title: 'Do you agree that beauty insecurities make it harder to succeed both in career and personal life?',
    options: {
      "yes": "Yes",
      "no": "No",
      "not_shure": "Not sure",
    },
  },
  [QUIZ.CLOTHES_SAY]: {
    type: 'single',
    title: 'What do you want your clothes to say about you?',
    options: {
      "professional": "Professional and confident",
      "powerful": "Powerful and successful",
      "young": "Young and vibrant",
      "chic": "Effortlessly chic",
      "fun": "Fun and playful",
      "none": "None of the above",
    },
  },
  [QUIZ.HOW_CONFIDENT]: {
    type: 'single',
    kind: QuizStepKind.Numeric,
    title: 'On a scale of 1-5, how confident do you feel in your current outfits?',
  },
  [QUIZ.AGE]: {
    type: 'single',
    title: 'How old are you?',
    options: {
      "18_29": "18–29",
      "30_39": "30–39",
      "40_49": "40–49",
      "over_50": "50+",
    },
  },
  [QUIZ.BODY_SHAPE]: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'What is your body shape?',
    options: {
      "hourglass": "Hourglass",
      "bottomHourglass": "Bottom Hourglass",
      "topHourglass": "Top Hourglass",
      "spoon": "Spoon",
      "invertedTriangle": "Inverted Triangle",
      "triangle": "Triangle",
      "rectangle": "Rectangle"
    },
    images: {
      "hourglass": IMAGES.HOURGLASS_LIGHT,
      "bottomHourglass": IMAGES.BOTTOM_HOURGLASS_LIGHT,
      "topHourglass": IMAGES.TOP_HOURGLASS_LIGHT,
      "spoon": IMAGES.SPOON_LIGHT,
      "invertedTriangle": IMAGES.INVERTED_TRIANGLE_LIGHT,
      "triangle": IMAGES.TRIANGLE_LIGHT,
      "rectangle": IMAGES.RECTANGLE_LIGHT
    },
    noteContent: {
      icon: '🤩',
      answers: {
        "hourglass": {
          title: 'Symmetrical, balanced, iconic',
          text: 'You`ve got that classic sexy silhouette, with curves in all the right places',
        },
        "bottomHourglass": {
          title: 'Graceful, elegant, feminine',
          text: 'You`ve got those gorgeous lower curves that balance elegance and allure',
        },
        "topHourglass": {
          title: 'Glamorous, dramatic, chic',
          text: 'You`ve got those extra-chic, alluring upper curves, right off the red carpet',
        },
        "spoon": {
          title: 'Classy, playful, eye-catching',
          text: 'You`ve got that beautifully rounded lower body, the essence of chic femininity',
        },
        "invertedTriangle": {
          title: 'Strong, bold, graceful',
          text: 'You`ve got that powerful, sporty figure, radiating confidence and strength',
        },
        "triangle": {
          title: 'Alluring, striking, vibrant',
          text: 'You`ve got that eye-catching, curvy appeal, radiating playful feminine elegance',
        },
        "rectangle": {
          title: 'Powerful, energetic, sporty',
          text: 'You`ve got that sleek and athletic figure, radiating elegance and graceful strength',
        },
        "default": {
          title: 'Your body shape',
          text: 'You have a wonderful figure',
        }
      }
    }
  },
  [QUIZ.EYES_COLOR]: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'Which eye color best describes yours?',
    options: {
      "brightBlue": "Bright blue",
      "lightBlue": "Light blue",
      "brightGreen": "Bright green",
      "lightGreen": "Light green",
      "gray": "Gray",
      "amber": "Amber",
      "lightHazel": "Light hazel",
      "darkHazel": "Dark hazel",
      "lightBrown": "Light brown",
      "darkBrown": "Dark brown",
      "mutedBrown": "Muted brown",
      "black": "Black"
    },
    noteContent: {
      icon: '👍',
      answers: {
        "brightBlue": {
          title: 'Bright blue',
          text: 'Soft purple, icy gray, and seafoam are likely to bring out the coolness of your eyes',
        },
        "lightBlue": {
          title: 'Light blue',
          text: 'Aqua, mint, and pale yellow are likely to intensify your eye color',
        },
        "brightGreen": {
          title: 'Bright green',
          text: 'Golden, turquoise, and blush tones are likely to enhance your vibrancy',
        },
        "lightGreen": {
          title: 'Light green',
          text: 'Peach, coral, and light teal are likely to highlight your eyes beautifully',
        },
        "gray": {
          title: 'Gray',
          text: 'Charcoal, silver, and navy shades are likely to harmonize with your eyes',
        },
        "amber": {
          title: 'Amber',
          text: 'Earthy browns, yellows, and rust hues are likely to bring out the warmth of your eyes',
        },
        "lightHazel": {
          title: 'Light hazel',
          text: 'Burnt orange, olive green, and taupe are likely to enhance your eye depth',
        },
        "darkHazel": {
          title: 'Dark hazel',
          text: 'Terracotta, peacock blue, and silver are likely to make your eyes pop',
        },
        "lightBrown": {
          title: 'Light brown',
          text: 'Creamy neutrals, plum, and gold tones are likely to highlight your eyes beautifully',
        },
        "darkBrown": {
          title: 'Dark brown',
          text: 'Cobalt blue, maroon, and ivory are likely to complement your eyes',
        },
        "mutedBrown": {
          title: 'Muted brown',
          text: 'Smoky grays, terracotta, and soft purples are likely to intensify your eye color',
        },
        "black": {
          title: 'Black',
          text: 'Bold violets, striking whites, and cerulean hues are likely to complement your eyes',
        },
        "default": {
          title: 'Your eyes',
          text: 'Your eyes are a beautiful and any color will complement them beautifully',
        }
      }
    },
    colors: EYES_COLORS,
  },
  [QUIZ.HAIR_COLOR]: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'Which hair color is most similar to yours?',
    options: {
      "ashBlonde": "Ash blonde",
      "goldenBlonde": "Golden blonde",
      "ashBrown": "Ash brown",
      "coolBrown": "Cool brown",
      "brown": "Brown",
      "warmBrown": "Warm brown",
      "strawberryBlonde": "Strawberry blonde",
      "copper": "Copper",
      "auburn": "Auburn",
      "darkAuburn": "Dark auburn",
      "brownBlack": "Brown black",
      "black": "Black",
    },
    noteContent: {
      icon: '👍',
      answers: {
        "ashBlonde": {
          title: "Ash blonde",
          text: "Light blues, soft grays, and blushing pinks are likely to complement your hair color",
        },
        "goldenBlonde": {
          title: "Golden blonde",
          text: "Sunny yellows, turquoise, and oranges are likely to amplify your warm glow",
        },
        "ashBrown": {
          title: "Ash brown",
          text: "Mocha, olive, and cranberry shades are likely to harmonize with your hair color",
        },
        "coolBrown": {
          title: "Cool brown",
          text: "Deep purples, icy silvers, and teal shades are likely to harmonize with your hair",
        },
        "brown": {
          title: "Brown",
          text: "Olive green, maroon, and creamy whites are likely to complement your hair",
        },
        "warmBrown": {
          title: "Warm brown",
          text: "Gold, emerald, and rust are likely to bring out the warmth in your hair",
        },
        "strawberryBlonde": {
          title: "Strawberry blonde",
          text: "Coral, light green, and honey are likely to highlight your rosy tones",
        },
        "copper": {
          title: "Copper",
          text: "Forest green, cinnamon, and rose gold are likely to match well with your warm hair",
        },
        "auburn": {
          title: "Auburn",
          text: "Rich teal, mustard yellow, and burgundy are likely to suit your rich hair color",
        },
        "darkAuburn": {
          title: "Dark auburn",
          text: "Charcoal, deep forest green, and russet are likely to highlight your rich tones",
        },
        "brownBlack": {
          title: "Brown black",
          text: "Ruby red, sapphire, and ivory shades are likely to set off your hair's richness",
        },
        "black": {
          title: "Black",
          text: "Electric blue, bright maroon, and silver are likely to enhance your striking contrast",
        },
        "default": {
          title: "Youer hair",
          text: "Your hair is a beautiful and any color will complement it beautifully",
        }
      }
    },
    colors: HAIR_COLORS
  },
  [QUIZ.SKIN_TONE]: {
    type: 'single',
    kind: QuizStepKind.Square,
    title: 'Which shade resembles your natural skin tone?',
    options: {
      "coolPale": "Cool pale",
      "porcelain": "Porcelain",
      "ivory": "Ivory",
      "peach": "Peach",
      "rosyBeige": "Rosy beige",
      "neutralBeige": "Neutral beige",
      "goldenBeige": "Golden beige",
      "olive": "Olive",
      "tan": "Tan",
      "bronze": "Bronze",
      "chocolate": "Chocolate",
      "espresso": "Espresso",
    },
    colors: SKIN_TONE
  },
  [QUIZ.TOP_SIZE]: {
    type: 'multi',
    kind: QuizStepKind.Square,
    title: 'What’s your usual top size?',
    contentUnderTitle: <SizeLinkModal analyticsCategory={QUIZ.TOP_SIZE} sizesTableData={TOP_SIZES} />,
    options: {
      "XXS": "XXS or smaller",
      "XS": "XS",
      "S": "S",
      "M": "M",
      "L": "L",
      "XL": "XL",
      "XXL": "XXL or larger",
    },
    images: {
      "XXS": IMAGES.T_SHIRT_XXS,
      "XS": IMAGES.T_SHIRT_XS,
      "S": IMAGES.T_SHIRT_S,
      "M": IMAGES.T_SHIRT_M,
      "L": IMAGES.T_SHIRT_L,
      "XL": IMAGES.T_SHIRT_XL,
      "XXL": IMAGES.T_SHIRT_XXL,
    },
  },
  [QUIZ.BOTTOM_SIZE]: {
    type: 'multi',
    kind: QuizStepKind.Square,
    title: 'What’s your usual bottom size?',
    contentUnderTitle: <SizeLinkModal analyticsCategory={QUIZ.BOTTOM_SIZE} sizesTableData={BOTTOM_SIZES} />,
    options: {
      "XXS": "XXS or smaller",
      "XS": "XS",
      "S": "S",
      "M": "M",
      "L": "L",
      "XL": "XL",
      "XXL": "XXL or larger",
    },
    images: {
      "XXS": IMAGES.BADGE_XXS,
      "XS": IMAGES.BADGE_XS,
      "S": IMAGES.BADGE_S,
      "M": IMAGES.BADGE_M,
      "L": IMAGES.BADGE_L,
      "XL": IMAGES.BADGE_XL,
      "XXL": IMAGES.BADGE_XXL,
    },
  }
}

export const getSteps = (theme?: ThemeMode) => {
  if (theme === 'light') {
    return Object.assign({}, STEPS, STEPS_LIGHT)
  }

  return STEPS
}


export type StyleSteps = keyof typeof STEPS;