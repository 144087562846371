export const baseTkey = 'quiz.sizes.modal'

export const TOP_SIZES = {
  inch: {
    columns: [
      { key: "size", label: "Letter\nsize" },
      { key: "bust", label: "Bust\n(in)" },
      { key: "waist", label: "Waist\n(in)" },
      { key: "hips", label: "Hips\n(in)" },
    ],
    rows: [
      { size: "XSS", bust: "30–31", waist: "22–23", hips: "32–33" },
      { size: "XS", bust: "32–33", waist: "24–25", hips: "34–35" },
      { size: "S", bust: "34–35", waist: "26–27", hips: "36–37" },
      { size: "M", bust: "36–37", waist: "28–29", hips: "38–39" },
      { size: "L", bust: "38–40", waist: "30–32", hips: "40–42" },
      { size: "XL", bust: "41–43", waist: "33–35", hips: "43–45" },
      { size: "XXL", bust: "44–46", waist: "36–38", hips: "46–48" },
    ]
  },
  cm: {
    columns: [
      { key: "size", label: "Letter\nsize" },
      { key: "bust", label: "Bust\n(cm)" },
      { key: "waist", label: "Waist\n(cm)" },
      { key: "hips", label: "Hips\n(cm)" },
    ],
    rows: [
      { size: "XSS", bust: "76–79", waist: "56–58", hips: "81–84" },
      { size: "XS", bust: "80–84", waist: "61–64", hips: "86–89" },
      { size: "S", bust: "85–89", waist: "66–69", hips: "91–94" },
      { size: "M", bust: "90–94", waist: "71–74", hips: "97–99" },
      { size: "L", bust: "97–102", waist: "76–81", hips: "102–107" },
      { size: "XL", bust: "104–109", waist: "84–89", hips: "109–114" },
      { size: "XXL", bust: "112–117", waist: "91–97", hips: "117–122" },
    ],
  }
};

export const BOTTOM_SIZES = {
  inch: {
    columns: [
      { key: "size", label: "Letter\nsize" },
      { key: "numberSize", label: "Number size\n(W/L)" },
      { key: "waist", label: "Waist\n(in)" },
      { key: "hips", label: "Hips\n(in)" },
    ],
    rows: [
      { size: "XSS", numberSize: "23/30", waist: "23–24", hips: "33–34" },
      { size: "XS", numberSize: "25/30", waist: "25–26", hips: "35–36" },
      { size: "S", numberSize: "27/31", waist: "27–28", hips: "37–38" },
      { size: "M", numberSize: "29/32", waist: "29–30", hips: "39–40" },
      { size: "L", numberSize: "31/32", waist: "31–32", hips: "41–42" },
      { size: "XL", numberSize: "33/33", waist: "33–34", hips: "43–44" },
      { size: "XXL", numberSize: "35/34", waist: "35–36", hips: "45–46" },
    ]
  },
  cm: {
    columns: [
      { key: "size", label: "Letter\nsize" },
      { key: "numberSize", label: "Number size\n(W/L)" },
      { key: "waist", label: "Waist\n(cm)" },
      { key: "hips", label: "Hips\n(cm)" },
    ],
    rows: [
      { size: "XSS", numberSize: "23/30", waist: "58–61", hips: "84–86" },
      { size: "XS", numberSize: "25/30", waist: "64–66", hips: "89–91" },
      { size: "S", numberSize: "27/31", waist: "69–71", hips: "94–97" },
      { size: "M", numberSize: "29/32", waist: "74–76", hips: "99–102" },
      { size: "L", numberSize: "31/32", waist: "79–81", hips: "104–107" },
      { size: "XL", numberSize: "33/33", waist: "84–86", hips: "109–112" },
      { size: "XXL", numberSize: "35/34", waist: "89–91", hips: "114–117" },
    ],
  }
};