import type { ImagesMap } from "@web-solutions/core/interfaces/images";

import T_SHIRT_L_1X from './t-shirt-l@1x.webp'
import T_SHIRT_L_2X from './t-shirt-l@2x.webp'
import T_SHIRT_L_3X from './t-shirt-l@3x.webp'

import T_SHIRT_M_1X from './t-shirt-m@1x.webp'
import T_SHIRT_M_2X from './t-shirt-m@2x.webp'
import T_SHIRT_M_3X from './t-shirt-m@3x.webp'

import T_SHIRT_S_1X from './t-shirt-s@1x.webp'
import T_SHIRT_S_2X from './t-shirt-s@2x.webp'
import T_SHIRT_S_3X from './t-shirt-s@3x.webp'

import T_SHIRT_XS_1X from './t-shirt-xs@1x.webp'
import T_SHIRT_XS_2X from './t-shirt-xs@2x.webp'
import T_SHIRT_XS_3X from './t-shirt-xs@3x.webp'

import T_SHIRT_XL_1X from './t-shirt-xl@1x.webp'
import T_SHIRT_XL_2X from './t-shirt-xl@2x.webp'
import T_SHIRT_XL_3X from './t-shirt-xl@3x.webp'

import T_SHIRT_XXL_1X from './t-shirt-xxl@1x.webp'
import T_SHIRT_XXL_2X from './t-shirt-xxl@2x.webp'
import T_SHIRT_XXL_3X from './t-shirt-xxl@3x.webp'

import T_SHIRT_XXS_1X from './t-shirt-xxs@1x.webp'
import T_SHIRT_XXS_2X from './t-shirt-xxs@2x.webp'
import T_SHIRT_XXS_3X from './t-shirt-xxs@3x.webp'

export const IMAGES: ImagesMap = {
  T_SHIRT_XS: {
    src: T_SHIRT_XS_1X,
    srcSet: `${T_SHIRT_XS_1X}, ${T_SHIRT_XS_2X} 2x, ${T_SHIRT_XS_3X} 3x`
  },
  T_SHIRT_S: {
    src: T_SHIRT_S_1X,
    srcSet: `${T_SHIRT_S_1X}, ${T_SHIRT_S_2X} 2x, ${T_SHIRT_S_3X} 3x`
  },
  T_SHIRT_M: {
    src: T_SHIRT_M_1X,
    srcSet: `${T_SHIRT_M_1X}, ${T_SHIRT_M_2X} 2x, ${T_SHIRT_M_3X} 3x`
  },
  T_SHIRT_L: {
    src: T_SHIRT_L_1X,
    srcSet: `${T_SHIRT_L_1X}, ${T_SHIRT_L_2X} 2x, ${T_SHIRT_L_3X} 3x`
  },
  T_SHIRT_XL: {
    src: T_SHIRT_XL_1X,
    srcSet: `${T_SHIRT_XL_1X}, ${T_SHIRT_XL_2X} 2x, ${T_SHIRT_XL_3X} 3x`
  },
  T_SHIRT_XXL: {
    src: T_SHIRT_XXL_1X,
    srcSet: `${T_SHIRT_XXL_1X}, ${T_SHIRT_XXL_2X} 2x, ${T_SHIRT_XXL_3X} 3x`
  },
  T_SHIRT_XXS: {
    src: T_SHIRT_XXS_1X,
    srcSet: `${T_SHIRT_XXS_1X}, ${T_SHIRT_XXS_2X} 2x, ${T_SHIRT_XXS_3X} 3x`
  }
}