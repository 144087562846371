import _intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import normalizeBlocks from 'core/utils/normalize-blocks';

import { STEPS } from 'src/screens/quiz/questions';
import { QUIZ } from 'src/constants/routes';

import { RootState } from '../types';

const ROUTES = Object.keys(STEPS).concat(Object.keys(QUIZ));

//@ts-ignore
const getFlow = (state) => state.remoteConfig.flow;
const getFlowWelcomeStep = (state: RootState) => state?.remoteConfig?.quizWelcomePage?.step;

export const selectFlowBlocks = createSelector([getFlow, getFlowWelcomeStep], (flow, flowWelcomeStep) => {
  let r = normalizeBlocks(flow);
  r = r.map(i => _intersection(i, ROUTES)).filter(i => !!i.length);

  if (flowWelcomeStep) {
    r = r.map((item, indx) => {
      if (indx === 0) {
        return [flowWelcomeStep, ...item]
      }

      return item
    })
  }

  return r;
});

export const selectFlow = createSelector([selectFlowBlocks], (flow) => {
  const flatFlow = flow.flat();
  return flatFlow;
});
