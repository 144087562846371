export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const PREFIX_WITH_PAYMENT = '-pp';

export const EVENT_ACTION = {
  OPEN: 'open',
  SUCCESS: 'success',
  ERROR: 'error',
  CLOSE: 'close',
  SKIP: 'skip',
  CLICK: 'click',
  SUBMIT: 'submit',
  CANCEL: 'cancel',
  DELETE: 'delete',
  SELECTED: 'selected',
  BACK: 'back',
  MOUNT: 'mount',
  SENT: 'sent'
};

export const SUPPORT_LANGUAGES: Record<string, string> = {
  en: 'en',
  de: 'de',
  es: 'es-ES',
  fr: 'fr',
  'pt-br': 'pt-BR',
  pt: 'pt-PT',
  ja: 'ja',
  ar: 'ar',
  'es-mx': 'es-MX',
  ko: 'ko',
  it: 'it',
}
