import { AppLinks } from '@web-solutions/module-attribution'

export const APP_NAME = 'Stylix';

export const PROJECT_SUPPORT_SUBJECT = '[Stylix WEB] ';

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const APP_LINKS: AppLinks = {
  ADJUST_LINK: 'https://stylix.go.link/p',
  ADJUST_TOKEN: '1cgyz58v',
};


export const SUPPORT_LANGUAGES: Record<string, string> = {
  en: 'en',
  de: 'de',
  es: 'es-ES',
  fr: 'fr',
  'pt-br': 'pt-BR',
  pt: 'pt-PT',
  ja: 'ja',
  ar: 'ar',
  it: 'it',
  'es-mx': 'es-MX'
}