import { BASE_QUIZ, type BaseQuizRoute, SUBSCRIPTION as BASE_SUBSCRIPTION } from '@web-solutions/base-app/constants/routes';

import { SUBSCRIPTION_NAMES, type AfterPurchaseRoutes } from './screens';

export const LOCAL_QUIZ = {
  WELCOME: "WELCOME",
  WELCOME_AGES: 'WELCOME_AGES',
  WELCOME_AGES_ALT: 'WELCOME_AGES_ALT',
  WELCOME_GENDER: 'WELCOME_GENDER',
  WELCOME_DRESS_BETTER: 'WELCOME_DRESS_BETTER',
  WELCOME_BUBBLES: 'WELCOME_BUBBLES',
  WELCOME_COLOR_AND_BODY: 'WELCOME_COLOR_AND_BODY',
  WELCOME_COLOR: 'WELCOME_COLOR',
  WELCOME_GUIDES: 'WELCOME_GUIDES',
  WELCOME_GUIDES_ALT: 'WELCOME_GUIDES_ALT',

  MAGIC: "MAGIC",
  FACE_READING: 'FACE_READING',
  LOADERS: 'LOADERS',

  MAIN_GOAL: 'MAIN_GOAL',
  FLATTER_COLORS: 'FLATTER_COLORS',
  FEEL_CONFIDENT: 'FEEL_CONFIDENT',
  CURRENT_WARDROBE: 'CURRENT_WARDROBE',
  FEEL_ANXIOUS: 'FEEL_ANXIOUS',
  STRUGGLE_PICKING_OUT: 'STRUGGLE_PICKING_OUT',
  MOST_FRUSTRATES: 'MOST_FRUSTRATES',
  WASTE_MONEY: 'WASTE_MONEY',
  MOST_COMFORTABLE: 'MOST_COMFORTABLE',
  MOST_RESONATES: 'MOST_RESONATES',
  WEAR_OUTFIT: 'WEAR_OUTFIT',
  BEAUTY_INSECURITIES: 'BEAUTY_INSECURITIES',
  CLOTHES_SAY: 'CLOTHES_SAY',
  HOW_CONFIDENT: 'HOW_CONFIDENT',
  AGE: 'AGE',
  HEIGHT: 'HEIGHT',
  WEIGHT: 'WEIGHT',
  BODY_SHAPE: 'BODY_SHAPE',
  EYES_COLOR: 'EYES_COLOR',
  HAIR_COLOR: 'HAIR_COLOR',
  SKIN_TONE: 'SKIN_TONE',
  TOP_SIZE: 'TOP_SIZE',
  BOTTOM_SIZE: 'BOTTOM_SIZE',

  TRANSITION_YOU_NOT_ALONE: 'TRANSITION_YOU_NOT_ALONE',
  TRANSITION_WORK_FOR_YOU: 'TRANSITION_WORK_FOR_YOU',
  TRANSITION_CAPSULE_WARDROBE: 'TRANSITION_CAPSULE_WARDROBE',
  TRANSITION_LOOK_BEST: 'TRANSITION_LOOK_BEST',
  TRANSITION_PERFECT_CUTS: 'TRANSITION_PERFECT_CUTS',
  TRANSITION_WHICH_SUIT: 'TRANSITION_WHICH_SUIT',
  TRANSITION_NOTHING_TO_WEAR: 'TRANSITION_NOTHING_TO_WEAR',
  TRANSITION_WARDROBE_REGRETS: 'TRANSITION_WARDROBE_REGRETS',
  TRANSITION_GAME: 'TRANSITION_GAME',
  TRANSITION_COLOR_TYPE: 'TRANSITION_COLOR_TYPE',
  TRANSITION_FORMULA: 'TRANSITION_FORMULA',
  TRANSITION_EVERY_OUTFIT: 'TRANSITION_EVERY_OUTFIT',
  TRANSITION_FACE_SCAN: 'TRANSITION_FACE_SCAN',

  PERSONAL_SUMMARY_BIG_PHOTO: 'PERSONAL_SUMMARY_BIG_PHOTO',
  PERSONAL_SUMMARY_SMALL_PHOTO: 'PERSONAL_SUMMARY_SMALL_PHOTO',

  WARDROBE_PROMO_UNIQUE_FORMULA: 'WARDROBE_PROMO_UNIQUE_FORMULA',
  WARDROBE_PROMO_TIMELESS_WARDROBE: 'WARDROBE_PROMO_TIMELESS_WARDROBE',
  WARDROBE_PROMO_OUTFITS_FOR_MOOD: 'WARDROBE_PROMO_OUTFITS_FOR_MOOD',
  WARDROBE_PROMO_WORK_FOR_YOU: 'WARDROBE_PROMO_WORK_FOR_YOU',
  WARDROBE_PROMO_LOOK_AMAZING: 'WARDROBE_PROMO_LOOK_AMAZING',
  WARDROBE_PROMO_CELEBRATE_BODY: 'WARDROBE_PROMO_CELEBRATE_BODY',

  WARDROBE_INSTRUCTION: 'WARDROBE_INSTRUCTION',

  WARDROBE_CHECKPOINT_CURRENT_WARDROBE: 'WARDROBE_CHECKPOINT_CURRENT_WARDROBE',
  WARDROBE_CHECKPOINT_STYLING: 'WARDROBE_CHECKPOINT_STYLING',
  WARDROBE_CHECKPOINT_SHOPPING: 'WARDROBE_CHECKPOINT_SHOPPING',
  WARDROBE_CHECKPOINT_OUTFITS: 'WARDROBE_CHECKPOINT_OUTFITS',
  WARDROBE_CHECKPOINT_VIBE: 'WARDROBE_CHECKPOINT_VIBE',
  WARDROBE_CHECKPOINT_FIT: 'WARDROBE_CHECKPOINT_FIT',
  WARDROBE_CHECKPOINT_COLORS: 'WARDROBE_CHECKPOINT_COLORS',
} as const;

export const QUIZ = {
  ...BASE_QUIZ,
  ...LOCAL_QUIZ,
} as const;

type QuizNameKeys = keyof typeof LOCAL_QUIZ;
export type QuizRoute = (typeof LOCAL_QUIZ[QuizNameKeys]) | BaseQuizRoute;

export const SUBSCRIPTION = {
  ...BASE_SUBSCRIPTION,
  [SUBSCRIPTION_NAMES.STYLE_GUIDE]: '/subscription/style-guide',
  [SUBSCRIPTION_NAMES.WARDROBE_GUIDE]: '/subscription/wardrobe-guide',
  [SUBSCRIPTION_NAMES.CREATE_ACCOUNT]: '/subscription/create-account',
  [SUBSCRIPTION_NAMES.PDF_GUIDES]: '/subscription/pdf-guides',
}

export const SCREENS_WITHOUT_PROGRESSBAR = [
  SUBSCRIPTION.MAIN,
  SUBSCRIPTION.SUCCESS,
  BASE_QUIZ.EMAIL,
  BASE_QUIZ.MAGIC,
  QUIZ.WELCOME,
  QUIZ.WELCOME_DRESS_BETTER,
  QUIZ.FACE_READING,
  QUIZ.PERSONAL_SUMMARY_BIG_PHOTO,
  QUIZ.PERSONAL_SUMMARY_SMALL_PHOTO,
  QUIZ.LOADERS,
];

export const MULTI_VARIANCE_SCREENS: string[] = []

export const ROUTES = null

export const AFTER_PURCHASE_ROUTES: AfterPurchaseRoutes = [
  SUBSCRIPTION_NAMES.STYLE_GUIDE,
  SUBSCRIPTION_NAMES.WARDROBE_GUIDE,
  SUBSCRIPTION_NAMES.CREATE_ACCOUNT,
  SUBSCRIPTION_NAMES.PDF_GUIDES
]
