import { type ComponentProps, type FC, useMemo, useState } from "react";
import { useSelector } from 'react-redux'
import classNames from "classnames";

import Analytics from "@web-solutions/module-analytics";
import { t } from '@web-solutions/module-localization'

import { Title } from "@web-solutions/core/ui-elements";

import type { RootState } from 'src/store/types';

import { TabsSwitch } from "../tabs-switch";

import { baseTkey } from "./constants";

import classes from "./styles.module.scss";

type Metrics = 'inch' | 'cm';

interface Column {
  key: string;
  label: string;
}

interface RowData {
  [key: string]: string | number;
}

export interface SizeTableProps extends ComponentProps<'div'> {
  analyticsCategory: string;
  data: Record<Metrics, {
    columns: Column[];
    rows: RowData[];
  }>
};

export const SizeTable: FC<SizeTableProps> = ({ analyticsCategory, data, className, ...props }) => {
  const measurmentSystem = useSelector((state: RootState) => state.profile.measurmentSystem);

  const TABS: Record<Metrics, { title: string }> = useMemo(() => ({
    inch: { title: t(`${baseTkey}.tabs.inch`) },
    cm: { title: t(`${baseTkey}.tabs.cm`) },
  }), []);

  const [activeTab, setActiveTab] = useState(Object.keys(TABS)[measurmentSystem === 'imperial' ? 0 : 1] as keyof typeof TABS);

  const handleTabChange = (key: keyof typeof TABS) => () => {
    Analytics.trackEvent(analyticsCategory, 'modal_tab_click', { tab: key })

    setActiveTab(key)
  }

  return (
    <div className={classNames(classes.sizeTableContainer, className)} {...props}>
      <div className={classes.header}>
        <Title level="h3" className={classes.title}>
          {t(`${baseTkey}.title`)}
        </Title>
        <TabsSwitch
          className={classes.tabs}
          tabs={TABS}
          activeTab={activeTab}
          onTabClick={handleTabChange}
        />
      </div>
      <table className={classes.table}>
        <thead>
          <tr>
            {data[activeTab].columns.map((col, index) => (
              <th key={index}>{col.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data[activeTab].rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {data[activeTab].columns.map((col, colIndex) => (
                <td key={colIndex}>{row[col.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
