import type { ImagesMap } from "@web-solutions/core/interfaces/images";

import BADGE_L_1X from './badge_l@1x.webp'
import BADGE_L_2X from './badge_l@2x.webp'
import BADGE_L_3X from './badge_l@3x.webp'

import BADGE_M_1X from './badge_m@1x.webp'
import BADGE_M_2X from './badge_m@2x.webp'
import BADGE_M_3X from './badge_m@3x.webp'

import BADGE_S_1X from './badge_s@1x.webp'
import BADGE_S_2X from './badge_s@2x.webp'
import BADGE_S_3X from './badge_s@3x.webp'

import BADGE_XS_1X from './badge_xs@1x.webp'
import BADGE_XS_2X from './badge_xs@2x.webp'
import BADGE_XS_3X from './badge_xs@3x.webp'

import BADGE_XXS_1X from './badge_xxs@1x.webp'
import BADGE_XXS_2X from './badge_xxs@2x.webp'
import BADGE_XXS_3X from './badge_xxs@3x.webp'

import BADGE_XXL_1X from './badge_xxl@1x.webp'
import BADGE_XXL_2X from './badge_xxl@2x.webp'
import BADGE_XXL_3X from './badge_xxl@3x.webp'

import BADGE_XL_1X from './badge_xl@1x.webp'
import BADGE_XL_2X from './badge_xl@2x.webp'
import BADGE_XL_3X from './badge_xl@3x.webp'

export const IMAGES: ImagesMap = {
  BADGE_XXS: {
    src: BADGE_XXS_1X,
    srcSet: `${BADGE_XXS_1X}, ${BADGE_XXS_2X} 2x, ${BADGE_XXS_3X} 3x`
  },
  BADGE_XS: {
    src: BADGE_XS_1X,
    srcSet: `${BADGE_XS_1X}, ${BADGE_XS_2X} 2x, ${BADGE_XS_3X} 3x`
  },
  BADGE_S: {
    src: BADGE_S_1X,
    srcSet: `${BADGE_S_1X}, ${BADGE_S_2X} 2x, ${BADGE_S_3X} 3x`
  },
  BADGE_M: {
    src: BADGE_M_1X,
    srcSet: `${BADGE_M_1X}, ${BADGE_M_2X} 2x, ${BADGE_M_3X} 3x`
  },
  BADGE_L: {
    src: BADGE_L_1X,
    srcSet: `${BADGE_L_1X}, ${BADGE_L_2X} 2x, ${BADGE_L_3X} 3x`
  },
  BADGE_XL: {
    src: BADGE_XL_1X,
    srcSet: `${BADGE_XL_1X}, ${BADGE_XL_2X} 2x, ${BADGE_XL_3X} 3x`
  },
  BADGE_XXL: {
    src: BADGE_XXL_1X,
    srcSet: `${BADGE_XXL_1X}, ${BADGE_XXL_2X} 2x, ${BADGE_XXL_3X} 3x`
  }
}