import type { ComponentProps } from 'react';
import classNames from 'classnames';

import classes from './styles.module.scss';

interface TabsSwitchProps<T = any> extends ComponentProps<'div'> {
  tabs: T
  activeTab: keyof T,
  onTabClick: (key: keyof T) => () => void
}

export const TabsSwitch = <T extends Record<string, Record<string, any>>>({
  className,
  tabs,
  activeTab,
  onTabClick,
  ...props
}: TabsSwitchProps<T>) => {
  const tabKeys = Object.keys(tabs);

  return (
    <div className={classNames(classes.tabs, className)} {...props}>
      {tabKeys.map((key) => (
        <div
          key={key}
          className={classNames(classes.tab, { [classes.active]: key === activeTab })}
          onClick={onTabClick(key)}
        >
          {tabs[key].title}
        </div>
      ))}
    </div>
  )
}
