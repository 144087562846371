import { useState, type FC } from 'react'

import { t } from '@web-solutions/module-localization';
import Analytics from '@web-solutions/module-analytics';

import { Title, Text, Image, LinkButton } from '@web-solutions/core/ui-elements'

import { Modal } from 'src/components/modal'

import { SizeTable, type SizeTableProps } from '../size-table';

import { baseTkey } from './constants'

import { IMAGES } from './images'

import { AttentionIcon } from './icons'

import classes from './styles.module.scss'

interface SizeLinkModalProps {
  analyticsCategory: string;
  sizesTableData: SizeTableProps['data'];
}

export const SizeLinkModal: FC<SizeLinkModalProps> = ({ analyticsCategory, sizesTableData }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    Analytics.trackEvent(analyticsCategory, 'modal_open_link_click')

    setIsOpen(true)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const handleCloseModalOnCross = () => {
    Analytics.trackEvent(analyticsCategory, 'modal_cross_click')

    handleCloseModal()
  }

  return (
    <>
      <div className={classes.linkWrapper}>
        <AttentionIcon />
        <LinkButton className={classes.link} onClick={handleOpenModal}>
          {t(`${baseTkey}.link`)}
        </LinkButton>
      </div>
      <Modal
        isOpen={isOpen}
        className={classes.modal}
        onCloseModal={handleCloseModal}
        onCrossButtonClick={handleCloseModalOnCross}
      >
        <SizeTable
          analyticsCategory={analyticsCategory}
          className={classes.table}
          data={sizesTableData}
        />
        <div className={classes.contentWrapper}>
          <Title className={classes.title}>
            {t(`${baseTkey}.content.title`)}
          </Title>
          <Text className={classes.text}>
            {t(`${baseTkey}.content.text`)}
          </Text>
          <Image img={IMAGES.SIZE} alt='sizes' />
        </div>
      </Modal>
    </>
  )
}
