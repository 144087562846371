import type { ImagesMap } from "@web-solutions/core/interfaces/images"

import SIZES_1X from './sizes@1x.webp';
import SIZES_2X from './sizes@2x.webp';
import SIZES_3X from './sizes@3x.webp';

export const IMAGES: ImagesMap = {
  SIZE: {
    src: SIZES_1X,
    srcSet: `${SIZES_1X} 1x, ${SIZES_2X} 2x, ${SIZES_3X} 3x`,
  }
}