import { ImagesMap } from 'core/interfaces/images';

// common
import OTHER_SMALL_1X from './common/other_small.webp';
import OTHER_SMALL_2X from './common/other_small@2x.webp';
import OTHER_SMALL_3X from './common/other_small@3x.webp';

import COOL_1X from './common/cool.webp';
import COOL_2X from './common/cool@2x.webp';
import COOL_3X from './common/cool@3x.webp';

import CUTE_1X from './common/cute.webp';
import CUTE_2X from './common/cute@2x.webp';
import CUTE_3X from './common/cute@3x.webp';

import LOVE_1X from './common/love.webp';
import LOVE_2X from './common/love@2x.webp';
import LOVE_3X from './common/love@3x.webp';

import OK_1X from './common/ok.webp';
import OK_2X from './common/ok@2x.webp';
import OK_3X from './common/ok@3x.webp';

// transitions
import TRANS_WHICH_SUIT_1X from './trans-which-suit/suit.webp';
import TRANS_WHICH_SUIT_2X from './trans-which-suit/suit@2x.webp';
import TRANS_WHICH_SUIT_3X from './trans-which-suit/suit@3x.webp';

import TRANS_NOTHING_TO_WEAR_1X from './trans-nothing-to-wear/nothing.webp';
import TRANS_NOTHING_TO_WEAR_2X from './trans-nothing-to-wear/nothing@2x.webp';
import TRANS_NOTHING_TO_WEAR_3X from './trans-nothing-to-wear/nothing@3x.webp';

import TRANS_WARDROBE_REGRETS_1X from './trans-wardrobe-regrets/regrets.webp';
import TRANS_WARDROBE_REGRETS_2X from './trans-wardrobe-regrets/regrets@2x.webp';
import TRANS_WARDROBE_REGRETS_3X from './trans-wardrobe-regrets/regrets@3x.webp';

import TRANS_STYLE_GAME_1X from './trans-style-game/game.webp';
import TRANS_STYLE_GAME_2X from './trans-style-game/game@2x.webp';
import TRANS_STYLE_GAME_3X from './trans-style-game/game@3x.webp';

import TRANS_COLOR_TYPE_1X from './trans-color-type/type.webp';
import TRANS_COLOR_TYPE_2X from './trans-color-type/type@2x.webp';
import TRANS_COLOR_TYPE_3X from './trans-color-type/type@3x.webp';

import TRANS_STYLE_FORMULA_1X from './trans-style-formula/formula.webp';
import TRANS_STYLE_FORMULA_2X from './trans-style-formula/formula@2x.webp';
import TRANS_STYLE_FORMULA_3X from './trans-style-formula/formula@3x.webp';

import TRANS_EVERY_OUTFIT_1X from './trans-every-outfit/outfit.webp';
import TRANS_EVERY_OUTFIT_2X from './trans-every-outfit/outfit@2x.webp';
import TRANS_EVERY_OUTFIT_3X from './trans-every-outfit/outfit@3x.webp';

import TRANS_WORK_FOR_YOU_1X from './trans-work-for-you/colors.webp';
import TRANS_WORK_FOR_YOU_2X from './trans-work-for-you/colors@2x.webp';
import TRANS_WORK_FOR_YOU_3X from './trans-work-for-you/colors@3x.webp';

import TRANS_NOT_ALONE_1X from './trans-you-not-alone/not_alone.webp';
import TRANS_NOT_ALONE_2X from './trans-you-not-alone/not_alone@2x.webp';
import TRANS_NOT_ALONE_3X from './trans-you-not-alone/not_alone@3x.webp';

import TRANS_CAPSULE_WARDROBE_1X from './trans-capsule-wardrobe/capsule_wardrobe.webp';
import TRANS_CAPSULE_WARDROBE_2X from './trans-capsule-wardrobe/capsule_wardrobe@2x.webp';
import TRANS_CAPSULE_WARDROBE_3X from './trans-capsule-wardrobe/capsule_wardrobe@3x.webp';

import TRANS_LOOK_BEST_1X from './trans-look-best/look_best.webp';
import TRANS_LOOK_BEST_2X from './trans-look-best/look_best@2x.webp';
import TRANS_LOOK_BEST_3X from './trans-look-best/look_best@3x.webp';

import TRANS_PERFECT_CUTS_1X from './trans-perfect-cuts/perfect_cuts.webp';
import TRANS_PERFECT_CUTS_2X from './trans-perfect-cuts/perfect_cuts@2x.webp';
import TRANS_PERFECT_CUTS_3X from './trans-perfect-cuts/perfect_cuts@3x.webp';

// quiz
import PERSONALITY_1X from './main-goal/personality.webp';
import PERSONALITY_2X from './main-goal/personality@2x.webp';
import PERSONALITY_3X from './main-goal/personality@3x.webp';

import THROUGH_DAY_1X from './main-goal/through_day.webp';
import THROUGH_DAY_2X from './main-goal/through_day@2x.webp';
import THROUGH_DAY_3X from './main-goal/through_day@3x.webp';

import BODY_TYPE_1X from './biggest-challenges/body_type.webp';
import BODY_TYPE_2X from './biggest-challenges/body_type@2x.webp';
import BODY_TYPE_3X from './biggest-challenges/body_type@3x.webp';

import EVENTS_1X from './biggest-challenges/events.webp';
import EVENTS_2X from './biggest-challenges/events@2x.webp';
import EVENTS_3X from './biggest-challenges/events@3x.webp';

import OUTFITS_1X from './biggest-challenges/outfits.webp';
import OUTFITS_2X from './biggest-challenges/outfits@2x.webp';
import OUTFITS_3X from './biggest-challenges/outfits@3x.webp';

import PALETTE_1X from './biggest-challenges/palette.webp';
import PALETTE_2X from './biggest-challenges/palette@2x.webp';
import PALETTE_3X from './biggest-challenges/palette@3x.webp';

import PERSONAL_STYLE_1X from './biggest-challenges/personal_style.webp';
import PERSONAL_STYLE_2X from './biggest-challenges/personal_style@2x.webp';
import PERSONAL_STYLE_3X from './biggest-challenges/personal_style@3x.webp';

import TRENDS_1X from './biggest-challenges/trends.webp';
import TRENDS_2X from './biggest-challenges/trends@2x.webp';
import TRENDS_3X from './biggest-challenges/trends@3x.webp';

import CASUAL_1X from './struggle-picking-out/casual.webp';
import CASUAL_2X from './struggle-picking-out/casual@2x.webp';
import CASUAL_3X from './struggle-picking-out/casual@3x.webp';

import EVERYDAY_1X from './struggle-picking-out/everyday.webp';
import EVERYDAY_2X from './struggle-picking-out/everyday@2x.webp';
import EVERYDAY_3X from './struggle-picking-out/everyday@3x.webp';

import NONE_1X from './struggle-picking-out/none.webp';
import NONE_2X from './struggle-picking-out/none@2x.webp';
import NONE_3X from './struggle-picking-out/none@3x.webp';

import SPECIAL_1X from './struggle-picking-out/special.webp';
import SPECIAL_2X from './struggle-picking-out/special@2x.webp';
import SPECIAL_3X from './struggle-picking-out/special@3x.webp';

import VACATIONS_1X from './struggle-picking-out/vacations.webp';
import VACATIONS_2X from './struggle-picking-out/vacations@2x.webp';
import VACATIONS_3X from './struggle-picking-out/vacations@3x.webp';

import WORK_1X from './struggle-picking-out/work.webp';
import WORK_2X from './struggle-picking-out/work@2x.webp';
import WORK_3X from './struggle-picking-out/work@3x.webp';

import JEANS_1X from './most-comfortable/jeans.webp';
import JEANS_2X from './most-comfortable/jeans@2x.webp';
import JEANS_3X from './most-comfortable/jeans@3x.webp';

import LEGGINGS_1X from './most-comfortable/leggings.webp';
import LEGGINGS_2X from './most-comfortable/leggings@2x.webp';
import LEGGINGS_3X from './most-comfortable/leggings@3x.webp';

import BIG_NONE_1X from './most-comfortable/none.webp';
import BIG_NONE_2X from './most-comfortable/none@2x.webp';
import BIG_NONE_3X from './most-comfortable/none@3x.webp';

import SHORTS_1X from './most-comfortable/shorts.webp';
import SHORTS_2X from './most-comfortable/shorts@2x.webp';
import SHORTS_3X from './most-comfortable/shorts@3x.webp';

import SKIRTS_1X from './most-comfortable/skirts.webp';
import SKIRTS_2X from './most-comfortable/skirts@2x.webp';
import SKIRTS_3X from './most-comfortable/skirts@3x.webp';

import SUITS_1X from './most-comfortable/suits.webp';
import SUITS_2X from './most-comfortable/suits@2x.webp';
import SUITS_3X from './most-comfortable/suits@3x.webp';

import SWEATPANTS_1X from './most-comfortable/sweatpants.webp';
import SWEATPANTS_2X from './most-comfortable/sweatpants@2x.webp';
import SWEATPANTS_3X from './most-comfortable/sweatpants@3x.webp';

import BOHO_1X from './most-resonates/boho.webp';
import BOHO_2X from './most-resonates/boho@2x.webp';
import BOHO_3X from './most-resonates/boho@3x.webp';

import CASUAL_STYLE_1X from './most-resonates/casual.webp';
import CASUAL_STYLE_2X from './most-resonates/casual@2x.webp';
import CASUAL_STYLE_3X from './most-resonates/casual@3x.webp';

import CLASSIC_1X from './most-resonates/classic.webp';
import CLASSIC_2X from './most-resonates/classic@2x.webp';
import CLASSIC_3X from './most-resonates/classic@3x.webp';

import ECLECTIC_1X from './most-resonates/eclectic.webp';
import ECLECTIC_2X from './most-resonates/eclectic@2x.webp';
import ECLECTIC_3X from './most-resonates/eclectic@3x.webp';

import EDGY_1X from './most-resonates/edgy.webp';
import EDGY_2X from './most-resonates/edgy@2x.webp';
import EDGY_3X from './most-resonates/edgy@3x.webp';

import GLAM_1X from './most-resonates/glam.webp';
import GLAM_2X from './most-resonates/glam@2x.webp';
import GLAM_3X from './most-resonates/glam@3x.webp';

import MINIMALISTIC_1X from './most-resonates/minimalist.webp';
import MINIMALISTIC_2X from './most-resonates/minimalist@2x.webp';
import MINIMALISTIC_3X from './most-resonates/minimalist@3x.webp';

import ROMANTIC_1X from './most-resonates/romantic.webp';
import ROMANTIC_2X from './most-resonates/romantic@2x.webp';
import ROMANTIC_3X from './most-resonates/romantic@3x.webp';

import PREPPY_1X from './most-resonates/preppy.webp';
import PREPPY_2X from './most-resonates/preppy@2x.webp';
import PREPPY_3X from './most-resonates/preppy@3x.webp';

import SPORTY_1X from './most-resonates/sporty.webp';
import SPORTY_2X from './most-resonates/sporty@2x.webp';
import SPORTY_3X from './most-resonates/sporty@3x.webp';

import STREET_1X from './most-resonates/street.webp';
import STREET_2X from './most-resonates/street@2x.webp';
import STREET_3X from './most-resonates/street@3x.webp';

import Y2K_1X from './most-resonates/y2k.webp';
import Y2K_2X from './most-resonates/y2k@2x.webp';
import Y2K_3X from './most-resonates/y2k@3x.webp';

import POWERFUL_1X from './outfit-you-love/powerful.webp';
import POWERFUL_2X from './outfit-you-love/powerful@2x.webp';
import POWERFUL_3X from './outfit-you-love/powerful@3x.webp';

import DIFFERENT_1X from './eyes-color/different.webp';
import DIFFERENT_2X from './eyes-color/different@2x.webp';
import DIFFERENT_3X from './eyes-color/different@3x.webp';

import APPLE_1X from './body-shape/apple.webp';
import APPLE_2X from './body-shape/apple@2x.webp';
import APPLE_3X from './body-shape/apple@3x.webp';

import HOURGLASS_1X from './body-shape/hourglass.webp';
import HOURGLASS_2X from './body-shape/hourglass@2x.webp';
import HOURGLASS_3X from './body-shape/hourglass@3x.webp';

import PEAR_1X from './body-shape/pear.webp';
import PEAR_2X from './body-shape/pear@2x.webp';
import PEAR_3X from './body-shape/pear@3x.webp';

import RECTANGLE_1X from './body-shape/rectangle.webp';
import RECTANGLE_2X from './body-shape/rectangle@2x.webp';
import RECTANGLE_3X from './body-shape/rectangle@3x.webp';

import TRIANGLE_1X from './body-shape/triangle.webp';
import TRIANGLE_2X from './body-shape/triangle@2x.webp';
import TRIANGLE_3X from './body-shape/triangle@3x.webp';

import BUDGET_1X from './wardrobe/budget.webp';
import BUDGET_2X from './wardrobe/budget@2x.webp';
import BUDGET_3X from './wardrobe/budget@3x.webp';

import MORNING_1X from './wardrobe/morning.webp';
import MORNING_2X from './wardrobe/morning@2x.webp';
import MORNING_3X from './wardrobe/morning@3x.webp';

import SHOPPING_1X from './wardrobe/shopping.webp';
import SHOPPING_2X from './wardrobe/shopping@2x.webp';
import SHOPPING_3X from './wardrobe/shopping@3x.webp';

import STYLE_1X from './wardrobe/style.webp';
import STYLE_2X from './wardrobe/style@2x.webp';
import STYLE_3X from './wardrobe/style@3x.webp';

// WARDROBE QUIZ
import OLD_18_24_1X from './how-old/18-24.webp';
import OLD_18_24_2X from './how-old/18-24@2x.webp';
import OLD_18_24_3X from './how-old/18-24@3x.webp';

import OLD_25_31_1X from './how-old/25-31.webp';
import OLD_25_31_2X from './how-old/25-31@2x.webp';
import OLD_25_31_3X from './how-old/25-31@3x.webp';

import OLD_32_38_1X from './how-old/32-38.webp';
import OLD_32_38_2X from './how-old/32-38@2x.webp';
import OLD_32_38_3X from './how-old/32-38@3x.webp';

import OLD_39_45_1X from './how-old/39-45.webp';
import OLD_39_45_2X from './how-old/39-45@2x.webp';
import OLD_39_45_3X from './how-old/39-45@3x.webp';

import OLD_46_50_1X from './how-old/46-50.webp';
import OLD_46_50_2X from './how-old/46-50@2x.webp';
import OLD_46_50_3X from './how-old/46-50@3x.webp';

import COLD_1X from './typical-weather/cold.webp';
import COLD_2X from './typical-weather/cold@2x.webp';
import COLD_3X from './typical-weather/cold@3x.webp';

import HUMID_1X from './typical-weather/humid.webp';
import HUMID_2X from './typical-weather/humid@2x.webp';
import HUMID_3X from './typical-weather/humid@3x.webp';

import RAINY_1X from './typical-weather/rainy.webp';
import RAINY_2X from './typical-weather/rainy@2x.webp';
import RAINY_3X from './typical-weather/rainy@3x.webp';

import WARM_1X from './typical-weather/warm.webp';
import WARM_2X from './typical-weather/warm@2x.webp';
import WARM_3X from './typical-weather/warm@3x.webp';

import WINDY_1X from './typical-weather/windy.webp';
import WINDY_2X from './typical-weather/windy@2x.webp';
import WINDY_3X from './typical-weather/windy@3x.webp';

import EVER_CHANGING_1X from './typical-weather/ever_changing.webp';
import EVER_CHANGING_2X from './typical-weather/ever_changing@2x.webp';
import EVER_CHANGING_3X from './typical-weather/ever_changing@3x.webp';

import SEASONAL_MIX_1X from './typical-weather/seasonal_mix.webp';
import SEASONAL_MIX_2X from './typical-weather/seasonal_mix@2x.webp';
import SEASONAL_MIX_3X from './typical-weather/seasonal_mix@3x.webp';

import DRESS_1X from './outfit-formula/dress.webp';
import DRESS_2X from './outfit-formula/dress@2x.webp';
import DRESS_3X from './outfit-formula/dress@3x.webp';

import BLOUSE_AND_SKIRT_1X from './outfit-formula/blouse_and_skirt.webp';
import BLOUSE_AND_SKIRT_2X from './outfit-formula/blouse_and_skirt@2x.webp';
import BLOUSE_AND_SKIRT_3X from './outfit-formula/blouse_and_skirt@3x.webp';

import HOODIE_AND_JEANS_1X from './outfit-formula/hoodie_and_jeans.webp';
import HOODIE_AND_JEANS_2X from './outfit-formula/hoodie_and_jeans@2x.webp';
import HOODIE_AND_JEANS_3X from './outfit-formula/hoodie_and_jeans@3x.webp';

import SHIRT_AND_PANTS_1X from './outfit-formula/shirt_and_pants.webp';
import SHIRT_AND_PANTS_2X from './outfit-formula/shirt_and_pants@2x.webp';
import SHIRT_AND_PANTS_3X from './outfit-formula/shirt_and_pants@3x.webp';

import SWEATER_AND_LEGGINGS_1X from './outfit-formula/sweater_and_leggings.webp';
import SWEATER_AND_LEGGINGS_2X from './outfit-formula/sweater_and_leggings@2x.webp';
import SWEATER_AND_LEGGINGS_3X from './outfit-formula/sweater_and_leggings@3x.webp';

import SWEATSHIRT_AND_SWEATPANTS_1X from './outfit-formula/sweatshirt_and_sweatpants.webp';
import SWEATSHIRT_AND_SWEATPANTS_2X from './outfit-formula/sweatshirt_and_sweatpants@2x.webp';
import SWEATSHIRT_AND_SWEATPANTS_3X from './outfit-formula/sweatshirt_and_sweatpants@3x.webp';

import BAGS_1X from './accessories/bags.webp';
import BAGS_2X from './accessories/bags@2x.webp';
import BAGS_3X from './accessories/bags@3x.webp';

import BELTS_1X from './accessories/belts.webp';
import BELTS_2X from './accessories/belts@2x.webp';
import BELTS_3X from './accessories/belts@3x.webp';

import EYEWEAR_1X from './accessories/eyewear.webp';
import EYEWEAR_2X from './accessories/eyewear@2x.webp';
import EYEWEAR_3X from './accessories/eyewear@3x.webp';

import GLOVES_1X from './accessories/gloves.webp';
import GLOVES_2X from './accessories/gloves@2x.webp';
import GLOVES_3X from './accessories/gloves@3x.webp';

import HEADWEAR_1X from './accessories/headwear.webp';
import HEADWEAR_2X from './accessories/headwear@2x.webp';
import HEADWEAR_3X from './accessories/headwear@3x.webp';

import JEWELRY_1X from './accessories/jewelry.webp';
import JEWELRY_2X from './accessories/jewelry@2x.webp';
import JEWELRY_3X from './accessories/jewelry@3x.webp';

import SCARVES_1X from './accessories/scarves.webp';
import SCARVES_2X from './accessories/scarves@2x.webp';
import SCARVES_3X from './accessories/scarves@3x.webp';

import ACCESSORIES_1X from './missing-pieces/accessories.webp';
import ACCESSORIES_2X from './missing-pieces/accessories@2x.webp';
import ACCESSORIES_3X from './missing-pieces/accessories@3x.webp';

import ATHLEISURE_1X from './missing-pieces/athleisure.webp';
import ATHLEISURE_2X from './missing-pieces/athleisure@2x.webp';
import ATHLEISURE_3X from './missing-pieces/athleisure@3x.webp';

import DRESSY_PIECES_1X from './missing-pieces/dressy_pieces.webp';
import DRESSY_PIECES_2X from './missing-pieces/dressy_pieces@2x.webp';
import DRESSY_PIECES_3X from './missing-pieces/dressy_pieces@3x.webp';

import EVERYDAY_BASICS_1X from './missing-pieces/everyday_basics.webp';
import EVERYDAY_BASICS_2X from './missing-pieces/everyday_basics@2x.webp';
import EVERYDAY_BASICS_3X from './missing-pieces/everyday_basics@3x.webp';

import FORMAL_ATTIRE_1X from './missing-pieces/formal_attire.webp';
import FORMAL_ATTIRE_2X from './missing-pieces/formal_attire@2x.webp';
import FORMAL_ATTIRE_3X from './missing-pieces/formal_attire@3x.webp';

import HAVE_EVERYTHING_1X from './missing-pieces/have_everything.webp';
import HAVE_EVERYTHING_2X from './missing-pieces/have_everything@2x.webp';
import HAVE_EVERYTHING_3X from './missing-pieces/have_everything@3x.webp';

import SEASONAL_WEAR_1X from './missing-pieces/seasonal_wear.webp';
import SEASONAL_WEAR_2X from './missing-pieces/seasonal_wear@2x.webp';
import SEASONAL_WEAR_3X from './missing-pieces/seasonal_wear@3x.webp';

import LOOSE_1X from './how-clothes-fit/loose.webp';
import LOOSE_2X from './how-clothes-fit/loose@2x.webp';
import LOOSE_3X from './how-clothes-fit/loose@3x.webp';

import OVERSIZED_1X from './how-clothes-fit/oversized.webp';
import OVERSIZED_2X from './how-clothes-fit/oversized@2x.webp';
import OVERSIZED_3X from './how-clothes-fit/oversized@3x.webp';

import REGULAR_1X from './how-clothes-fit/regular.webp';
import REGULAR_2X from './how-clothes-fit/regular@2x.webp';
import REGULAR_3X from './how-clothes-fit/regular@3x.webp';

import TAILORED_1X from './how-clothes-fit/tailored.webp';
import TAILORED_2X from './how-clothes-fit/tailored@2x.webp';
import TAILORED_3X from './how-clothes-fit/tailored@3x.webp';

import TIGHT_1X from './how-clothes-fit/tight.webp';
import TIGHT_2X from './how-clothes-fit/tight@2x.webp';
import TIGHT_3X from './how-clothes-fit/tight@3x.webp';

import MY_ARMS_1X from './favorite-feature/my_arms.webp';
import MY_ARMS_2X from './favorite-feature/my_arms@2x.webp';
import MY_ARMS_3X from './favorite-feature/my_arms@3x.webp';

import MY_BUST_1X from './favorite-feature/my_bust.webp';
import MY_BUST_2X from './favorite-feature/my_bust@2x.webp';
import MY_BUST_3X from './favorite-feature/my_bust@3x.webp';

import MY_LEGS_1X from './favorite-feature/my_legs.webp';
import MY_LEGS_2X from './favorite-feature/my_legs@2x.webp';
import MY_LEGS_3X from './favorite-feature/my_legs@3x.webp';

import MY_NECK_1X from './favorite-feature/my_neck.webp';
import MY_NECK_2X from './favorite-feature/my_neck@2x.webp';
import MY_NECK_3X from './favorite-feature/my_neck@3x.webp';

import MY_SHOULDERS_1X from './favorite-feature/my_shoulders.webp';
import MY_SHOULDERS_2X from './favorite-feature/my_shoulders@2x.webp';
import MY_SHOULDERS_3X from './favorite-feature/my_shoulders@3x.webp';

import MY_WAIST_1X from './favorite-feature/my_waist.webp';
import MY_WAIST_2X from './favorite-feature/my_waist@2x.webp';
import MY_WAIST_3X from './favorite-feature/my_waist@3x.webp';

import * as STRUGGLE_PICKING_LIGHT from './struggle-picking-out/light'

import * as MOST_COMFORTABLE_LIGHT from './most-comfortable/light'

import * as BODY_SHAPE_LIGHT from './body-shape/light'

import { IMAGES as TOP_SIZE_IMAGES } from './top-size'
import { IMAGES as BOTTOM_SIZE_IMAGES } from './bottom-size'

import {
  SMALL_OTHER_BUTTON_1X,
  SMALL_OTHER_BUTTON_2X,
  SMALL_OTHER_BUTTON_3X,

  SMALL_NONE_BUTTON_1X,
  SMALL_NONE_BUTTON_2X,
  SMALL_NONE_BUTTON_3X,

  NONE_LIGHT_1X,
  NONE_LIGHT_2X,
  NONE_LIGHT_3X,

  OTHER_LIGHT_1X,
  OTHER_LIGHT_2X,
  OTHER_LIGHT_3X,
} from './common'


export const IMAGES: ImagesMap = {
  // common
  OTHER_SMALL: {
    src: OTHER_SMALL_1X,
    srcSet: `${OTHER_SMALL_1X}, ${OTHER_SMALL_2X} 2x, ${OTHER_SMALL_3X} 3x`
  },
  COOL: {
    src: COOL_1X,
    srcSet: `${COOL_1X}, ${COOL_2X} 2x, ${COOL_3X} 3x`
  },
  CUTE: {
    src: CUTE_1X,
    srcSet: `${CUTE_1X}, ${CUTE_2X} 2x, ${CUTE_3X} 3x`
  },
  LOVE: {
    src: LOVE_1X,
    srcSet: `${LOVE_1X}, ${LOVE_2X} 2x, ${LOVE_3X} 3x`
  },
  OK: {
    src: OK_1X,
    srcSet: `${OK_1X}, ${OK_2X} 2x, ${OK_3X} 3x`
  },
  // transitions
  TRANS_WHICH_SUIT: {
    src: TRANS_WHICH_SUIT_1X,
    srcSet: `${TRANS_WHICH_SUIT_1X}, ${TRANS_WHICH_SUIT_2X} 2x, ${TRANS_WHICH_SUIT_3X} 3x`
  },
  TRANS_NOTHING_TO_WEAR: {
    src: TRANS_NOTHING_TO_WEAR_1X,
    srcSet: `${TRANS_NOTHING_TO_WEAR_1X}, ${TRANS_NOTHING_TO_WEAR_2X} 2x, ${TRANS_NOTHING_TO_WEAR_3X} 3x`
  },
  TRANS_WARDROBE_REGRETS: {
    src: TRANS_WARDROBE_REGRETS_1X,
    srcSet: `${TRANS_WARDROBE_REGRETS_1X}, ${TRANS_WARDROBE_REGRETS_2X} 2x, ${TRANS_WARDROBE_REGRETS_3X} 3x`
  },
  TRANS_STYLE_GAME: {
    src: TRANS_STYLE_GAME_1X,
    srcSet: `${TRANS_STYLE_GAME_1X}, ${TRANS_STYLE_GAME_2X} 2x, ${TRANS_STYLE_GAME_3X} 3x`
  },
  TRANS_COLOR_TYPE: {
    src: TRANS_COLOR_TYPE_1X,
    srcSet: `${TRANS_COLOR_TYPE_1X}, ${TRANS_COLOR_TYPE_2X} 2x, ${TRANS_COLOR_TYPE_3X} 3x`
  },
  TRANS_STYLE_FORMULA: {
    src: TRANS_STYLE_FORMULA_1X,
    srcSet: `${TRANS_STYLE_FORMULA_1X}, ${TRANS_STYLE_FORMULA_2X} 2x, ${TRANS_STYLE_FORMULA_3X} 3x`
  },
  TRANS_EVERY_OUTFIT: {
    src: TRANS_EVERY_OUTFIT_1X,
    srcSet: `${TRANS_EVERY_OUTFIT_1X}, ${TRANS_EVERY_OUTFIT_2X} 2x, ${TRANS_EVERY_OUTFIT_3X} 3x`
  },
  TRANS_WORK_FOR_YOU: {
    src: TRANS_WORK_FOR_YOU_1X,
    srcSet: `${TRANS_WORK_FOR_YOU_1X}, ${TRANS_WORK_FOR_YOU_2X} 2x, ${TRANS_WORK_FOR_YOU_3X} 3x`
  },
  TRANS_YOU_NOT_ALONE: {
    src: TRANS_NOT_ALONE_1X,
    srcSet: `${TRANS_NOT_ALONE_1X}, ${TRANS_NOT_ALONE_2X} 2x, ${TRANS_NOT_ALONE_3X} 3x`
  },
  TRANS_CAPSULE_WARDROBE: {
    src: TRANS_CAPSULE_WARDROBE_1X,
    srcSet: `${TRANS_CAPSULE_WARDROBE_1X}, ${TRANS_CAPSULE_WARDROBE_2X} 2x, ${TRANS_CAPSULE_WARDROBE_3X} 3x`
  },
  TRANS_LOOK_BEST: {
    src: TRANS_LOOK_BEST_1X,
    srcSet: `${TRANS_LOOK_BEST_1X}, ${TRANS_LOOK_BEST_2X} 2x, ${TRANS_LOOK_BEST_3X} 3x`
  },
  TRANS_PERFECT_CUTS: {
    src: TRANS_PERFECT_CUTS_1X,
    srcSet: `${TRANS_PERFECT_CUTS_1X}, ${TRANS_PERFECT_CUTS_2X} 2x, ${TRANS_PERFECT_CUTS_3X} 3x`
  },
  // quiz
  PERSONALITY: {
    src: PERSONALITY_1X,
    srcSet: `${PERSONALITY_1X}, ${PERSONALITY_2X} 2x, ${PERSONALITY_3X} 3x`
  },
  THROUGH_DAY: {
    src: THROUGH_DAY_1X,
    srcSet: `${THROUGH_DAY_1X}, ${THROUGH_DAY_2X} 2x, ${THROUGH_DAY_3X} 3x`
  },
  BODY_TYPE: {
    src: BODY_TYPE_1X,
    srcSet: `${BODY_TYPE_1X}, ${BODY_TYPE_2X} 2x, ${BODY_TYPE_3X} 3x`
  },
  EVENTS: {
    src: EVENTS_1X,
    srcSet: `${EVENTS_1X}, ${EVENTS_2X} 2x, ${EVENTS_3X} 3x`
  },
  OUTFITS: {
    src: OUTFITS_1X,
    srcSet: `${OUTFITS_1X}, ${OUTFITS_2X} 2x, ${OUTFITS_3X} 3x`
  },
  PALETTE: {
    src: PALETTE_1X,
    srcSet: `${PALETTE_1X}, ${PALETTE_2X} 2x, ${PALETTE_3X} 3x`
  },
  PERSONAL_STYLE: {
    src: PERSONAL_STYLE_1X,
    srcSet: `${PERSONAL_STYLE_1X}, ${PERSONAL_STYLE_2X} 2x, ${PERSONAL_STYLE_3X} 3x`
  },
  TRENDS: {
    src: TRENDS_1X,
    srcSet: `${TRENDS_1X}, ${TRENDS_2X} 2x, ${TRENDS_3X} 3x`
  },
  CASUAL: {
    src: CASUAL_1X,
    srcSet: `${CASUAL_1X}, ${CASUAL_2X} 2x, ${CASUAL_3X} 3x`
  },
  EVERYDAY: {
    src: EVERYDAY_1X,
    srcSet: `${EVERYDAY_1X}, ${EVERYDAY_2X} 2x, ${EVERYDAY_3X} 3x`
  },
  NONE: {
    src: NONE_1X,
    srcSet: `${NONE_1X}, ${NONE_2X} 2x, ${NONE_3X} 3x`
  },
  SPECIAL: {
    src: SPECIAL_1X,
    srcSet: `${SPECIAL_1X}, ${SPECIAL_2X} 2x, ${SPECIAL_3X} 3x`
  },
  VACATIONS: {
    src: VACATIONS_1X,
    srcSet: `${VACATIONS_1X}, ${VACATIONS_2X} 2x, ${VACATIONS_3X} 3x`
  },
  WORK: {
    src: WORK_1X,
    srcSet: `${WORK_1X}, ${WORK_2X} 2x, ${WORK_3X} 3x`
  },
  JEANS: {
    src: JEANS_1X,
    srcSet: `${JEANS_1X}, ${JEANS_2X} 2x, ${JEANS_3X} 3x`
  },
  LEGGINGS: {
    src: LEGGINGS_1X,
    srcSet: `${LEGGINGS_1X}, ${LEGGINGS_2X} 2x, ${LEGGINGS_3X} 3x`
  },
  BIG_NONE: {
    src: BIG_NONE_1X,
    srcSet: `${BIG_NONE_1X}, ${BIG_NONE_2X} 2x, ${BIG_NONE_3X} 3x`
  },
  SHORTS: {
    src: SHORTS_1X,
    srcSet: `${SHORTS_1X}, ${SHORTS_2X} 2x, ${SHORTS_3X} 3x`
  },
  SKIRTS: {
    src: SKIRTS_1X,
    srcSet: `${SKIRTS_1X}, ${SKIRTS_2X} 2x, ${SKIRTS_3X} 3x`
  },
  SUITS: {
    src: SUITS_1X,
    srcSet: `${SUITS_1X}, ${SUITS_2X} 2x, ${SUITS_3X} 3x`
  },
  SWEATPANTS: {
    src: SWEATPANTS_1X,
    srcSet: `${SWEATPANTS_1X}, ${SWEATPANTS_2X} 2x, ${SWEATPANTS_3X} 3x`
  },
  BOHO: {
    src: BOHO_1X,
    srcSet: `${BOHO_1X}, ${BOHO_2X} 2x, ${BOHO_3X} 3x`
  },
  CASUAL_STYLE: {
    src: CASUAL_STYLE_1X,
    srcSet: `${CASUAL_STYLE_1X}, ${CASUAL_STYLE_2X} 2x, ${CASUAL_STYLE_3X} 3x`
  },
  CLASSIC: {
    src: CLASSIC_1X,
    srcSet: `${CLASSIC_1X}, ${CLASSIC_2X} 2x, ${CLASSIC_3X} 3x`
  },
  ECLECTIC: {
    src: ECLECTIC_1X,
    srcSet: `${ECLECTIC_1X}, ${ECLECTIC_2X} 2x, ${ECLECTIC_3X} 3x`
  },
  EDGY: {
    src: EDGY_1X,
    srcSet: `${EDGY_1X}, ${EDGY_2X} 2x, ${EDGY_3X} 3x`
  },
  GLAM: {
    src: GLAM_1X,
    srcSet: `${GLAM_1X}, ${GLAM_2X} 2x, ${GLAM_3X} 3x`
  },
  MINIMALISTIC: {
    src: MINIMALISTIC_1X,
    srcSet: `${MINIMALISTIC_1X}, ${MINIMALISTIC_2X} 2x, ${MINIMALISTIC_3X} 3x`
  },
  ROMANTIC: {
    src: ROMANTIC_1X,
    srcSet: `${ROMANTIC_1X}, ${ROMANTIC_2X} 2x, ${ROMANTIC_3X} 3x`
  },
  PREPPY: {
    src: PREPPY_1X,
    srcSet: `${PREPPY_1X}, ${PREPPY_2X} 2x, ${PREPPY_3X} 3x`
  },
  SPORTY: {
    src: SPORTY_1X,
    srcSet: `${SPORTY_1X}, ${SPORTY_2X} 2x, ${SPORTY_3X} 3x`
  },
  STREET: {
    src: STREET_1X,
    srcSet: `${STREET_1X}, ${STREET_2X} 2x, ${STREET_3X} 3x`
  },
  Y2K: {
    src: Y2K_1X,
    srcSet: `${Y2K_1X}, ${Y2K_2X} 2x, ${Y2K_3X} 3x`
  },
  POWERFUL: {
    src: POWERFUL_1X,
    srcSet: `${POWERFUL_1X}, ${POWERFUL_2X} 2x, ${POWERFUL_3X} 3x`
  },
  DIFFERENT: {
    src: DIFFERENT_1X,
    srcSet: `${DIFFERENT_1X}, ${DIFFERENT_2X} 2x, ${DIFFERENT_3X} 3x`
  },
  APPLE: {
    src: APPLE_1X,
    srcSet: `${APPLE_1X}, ${APPLE_2X} 2x, ${APPLE_3X} 3x`
  },
  HOURGLASS: {
    src: HOURGLASS_1X,
    srcSet: `${HOURGLASS_1X}, ${HOURGLASS_2X} 2x, ${HOURGLASS_3X} 3x`
  },
  PEAR: {
    src: PEAR_1X,
    srcSet: `${PEAR_1X}, ${PEAR_2X} 2x, ${PEAR_3X} 3x`
  },
  RECTANGLE: {
    src: RECTANGLE_1X,
    srcSet: `${RECTANGLE_1X}, ${RECTANGLE_2X} 2x, ${RECTANGLE_3X} 3x`
  },
  TRIANGLE: {
    src: TRIANGLE_1X,
    srcSet: `${TRIANGLE_1X}, ${TRIANGLE_2X} 2x, ${TRIANGLE_3X} 3x`
  },
  BUDGET: {
    src: BUDGET_1X,
    srcSet: `${BUDGET_1X}, ${BUDGET_2X} 2x, ${BUDGET_3X} 3x`
  },
  MORNING: {
    src: MORNING_1X,
    srcSet: `${MORNING_1X}, ${MORNING_2X} 2x, ${MORNING_3X} 3x`
  },
  SHOPPING: {
    src: SHOPPING_1X,
    srcSet: `${SHOPPING_1X}, ${SHOPPING_2X} 2x, ${SHOPPING_3X} 3x`
  },
  STYLE: {
    src: STYLE_1X,
    srcSet: `${STYLE_1X}, ${STYLE_2X} 2x, ${STYLE_3X} 3x`
  },

  WORK_LIGHT: {
    src: STRUGGLE_PICKING_LIGHT.WORK_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.WORK_1X}, ${STRUGGLE_PICKING_LIGHT.WORK_2X} 2x, ${STRUGGLE_PICKING_LIGHT.WORK_3X} 3x`
  },
  DATE_LIGHT: {
    src: STRUGGLE_PICKING_LIGHT.DATE_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.DATE_1X}, ${STRUGGLE_PICKING_LIGHT.DATE_2X} 2x, ${STRUGGLE_PICKING_LIGHT.DATE_3X} 3x`
  },
  CASUAL_LIGHT: {
    src: STRUGGLE_PICKING_LIGHT.CASUAL_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.CASUAL_1X}, ${STRUGGLE_PICKING_LIGHT.CASUAL_2X} 2x, ${STRUGGLE_PICKING_LIGHT.CASUAL_3X} 3x`
  },
  SPECIAL_EVENTS_LIGHT: {
    src: STRUGGLE_PICKING_LIGHT.SPECIAL_EVENTS_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.SPECIAL_EVENTS_1X}, ${STRUGGLE_PICKING_LIGHT.SPECIAL_EVENTS_2X} 2x, ${STRUGGLE_PICKING_LIGHT.SPECIAL_EVENTS_3X} 3x`
  },
  VACATION_LIGHT: {
    src: STRUGGLE_PICKING_LIGHT.VACATION_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.VACATION_1X}, ${STRUGGLE_PICKING_LIGHT.VACATION_2X} 2x, ${STRUGGLE_PICKING_LIGHT.VACATION_3X} 3x`
  },
  EVERY_DAY_LIGHT: {
    src: STRUGGLE_PICKING_LIGHT.EVERY_DAY_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.EVERY_DAY_1X}, ${STRUGGLE_PICKING_LIGHT.EVERY_DAY_2X} 2x, ${STRUGGLE_PICKING_LIGHT.EVERY_DAY_3X} 3x`
  },
  SMALL_NONE_BUTTON: {
    src: SMALL_NONE_BUTTON_1X,
    srcSet: `${SMALL_NONE_BUTTON_1X}, ${SMALL_NONE_BUTTON_2X} 2x, ${SMALL_NONE_BUTTON_3X} 3x`
  },
  NONE_LIGHT: {
    src: NONE_LIGHT_1X,
    srcSet: `${NONE_LIGHT_1X}, ${NONE_LIGHT_2X} 2x, ${NONE_LIGHT_3X} 3x`
  },
  SMALL_OTHER_BUTTON: {
    src: SMALL_OTHER_BUTTON_1X,
    srcSet: `${SMALL_OTHER_BUTTON_1X}, ${SMALL_OTHER_BUTTON_2X} 2x, ${SMALL_OTHER_BUTTON_3X} 3x`
  },
  OTHER_LIGHT: {
    src: OTHER_LIGHT_1X,
    srcSet: `${OTHER_LIGHT_1X}, ${OTHER_LIGHT_2X} 2x, ${OTHER_LIGHT_3X} 3x`
  },

  JEANS_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.JEANS_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.JEANS_1X}, ${MOST_COMFORTABLE_LIGHT.JEANS_2X} 2x, ${MOST_COMFORTABLE_LIGHT.JEANS_3X} 3x`
  },
  SKIRTS_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.SKIRTS_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.SKIRTS_1X}, ${MOST_COMFORTABLE_LIGHT.SKIRTS_2X} 2x, ${MOST_COMFORTABLE_LIGHT.SKIRTS_3X} 3x`
  },
  SUITS_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.SUITS_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.SUITS_1X}, ${MOST_COMFORTABLE_LIGHT.SUITS_2X} 2x, ${MOST_COMFORTABLE_LIGHT.SUITS_3X} 3x`
  },
  SHORTS_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.SHORTS_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.SHORTS_1X}, ${MOST_COMFORTABLE_LIGHT.SHORTS_2X} 2x, ${MOST_COMFORTABLE_LIGHT.SHORTS_3X} 3x`
  },
  LEGGINGS_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.LEGGINGS_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.LEGGINGS_1X}, ${MOST_COMFORTABLE_LIGHT.LEGGINGS_2X} 2x, ${MOST_COMFORTABLE_LIGHT.LEGGINGS_3X} 3x`
  },
  SWEATPANTS_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.SWEATPANTS_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.SWEATPANTS_1X}, ${MOST_COMFORTABLE_LIGHT.SWEATPANTS_2X} 2x, ${MOST_COMFORTABLE_LIGHT.SWEATPANTS_3X} 3x`
  },
  DRESSES_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.DRESSES_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.DRESSES_1X}, ${MOST_COMFORTABLE_LIGHT.DRESSES_2X} 2x, ${MOST_COMFORTABLE_LIGHT.DRESSES_3X} 3x`
  },

  HOURGLASS_LIGHT: {
    src: BODY_SHAPE_LIGHT.HOURGLASS_1X,
    srcSet: `${BODY_SHAPE_LIGHT.HOURGLASS_1X}, ${BODY_SHAPE_LIGHT.HOURGLASS_2X} 2x, ${BODY_SHAPE_LIGHT.HOURGLASS_3X} 3x`
  },
  BOTTOM_HOURGLASS_LIGHT: {
    src: BODY_SHAPE_LIGHT.BOTTOM_HOURGLASS_1X,
    srcSet: `${BODY_SHAPE_LIGHT.BOTTOM_HOURGLASS_1X}, ${BODY_SHAPE_LIGHT.BOTTOM_HOURGLASS_2X} 2x, ${BODY_SHAPE_LIGHT.BOTTOM_HOURGLASS_3X} 3x`
  },
  TOP_HOURGLASS_LIGHT: {
    src: BODY_SHAPE_LIGHT.TOP_HOURGLASS_1X,
    srcSet: `${BODY_SHAPE_LIGHT.TOP_HOURGLASS_1X}, ${BODY_SHAPE_LIGHT.TOP_HOURGLASS_2X} 2x, ${BODY_SHAPE_LIGHT.TOP_HOURGLASS_3X} 3x`
  },
  SPOON_LIGHT: {
    src: BODY_SHAPE_LIGHT.SPOON_1X,
    srcSet: `${BODY_SHAPE_LIGHT.SPOON_1X}, ${BODY_SHAPE_LIGHT.SPOON_2X} 2x, ${BODY_SHAPE_LIGHT.SPOON_3X} 3x`
  },
  INVERTED_TRIANGLE_LIGHT: {
    src: BODY_SHAPE_LIGHT.INVERTED_TRIANGLE_1X,
    srcSet: `${BODY_SHAPE_LIGHT.INVERTED_TRIANGLE_1X}, ${BODY_SHAPE_LIGHT.INVERTED_TRIANGLE_2X} 2x, ${BODY_SHAPE_LIGHT.INVERTED_TRIANGLE_3X} 3x`
  },
  TRIANGLE_LIGHT: {
    src: BODY_SHAPE_LIGHT.TRIANGLE_1X,
    srcSet: `${BODY_SHAPE_LIGHT.TRIANGLE_1X}, ${BODY_SHAPE_LIGHT.TRIANGLE_2X} 2x, ${BODY_SHAPE_LIGHT.TRIANGLE_3X} 3x`
  },
  RECTANGLE_LIGHT: {
    src: BODY_SHAPE_LIGHT.RECTANGLE_1X,
    srcSet: `${BODY_SHAPE_LIGHT.RECTANGLE_1X}, ${BODY_SHAPE_LIGHT.RECTANGLE_2X} 2x, ${BODY_SHAPE_LIGHT.RECTANGLE_3X} 3x`
  },

  // WARDROBE QUIZ
  OLD_18_24: {
    src: OLD_18_24_1X,
    srcSet: `${OLD_18_24_1X}, ${OLD_18_24_2X} 2x, ${OLD_18_24_3X} 3x`
  },
  OLD_25_31: {
    src: OLD_25_31_1X,
    srcSet: `${OLD_25_31_1X}, ${OLD_25_31_2X} 2x, ${OLD_25_31_3X} 3x`
  },
  OLD_32_38: {
    src: OLD_32_38_1X,
    srcSet: `${OLD_32_38_1X}, ${OLD_32_38_2X} 2x, ${OLD_32_38_3X} 3x`
  },
  OLD_39_45: {
    src: OLD_39_45_1X,
    srcSet: `${OLD_39_45_1X}, ${OLD_39_45_2X} 2x, ${OLD_39_45_3X} 3x`
  },
  OLD_46_50: {
    src: OLD_46_50_1X,
    srcSet: `${OLD_46_50_1X}, ${OLD_46_50_2X} 2x, ${OLD_46_50_3X} 3x`
  },

  ACTIVE_VACATION: {
    src: STRUGGLE_PICKING_LIGHT.ACTIVE_VACATION_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.ACTIVE_VACATION_1X}, ${STRUGGLE_PICKING_LIGHT.ACTIVE_VACATION_2X} 2x, ${STRUGGLE_PICKING_LIGHT.ACTIVE_VACATION_3X} 3x`
  },
  WORKOUTS: {
    src: STRUGGLE_PICKING_LIGHT.WORKOUTS_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.WORKOUTS_1X}, ${STRUGGLE_PICKING_LIGHT.WORKOUTS_2X} 2x, ${STRUGGLE_PICKING_LIGHT.WORKOUTS_3X} 3x`
  },

  COLD: {
    src: COLD_1X,
    srcSet: `${COLD_1X}, ${COLD_2X} 2x, ${COLD_3X} 3x`
  },
  HUMID: {
    src: HUMID_1X,
    srcSet: `${HUMID_1X}, ${HUMID_2X} 2x, ${HUMID_3X} 3x`
  },
  RAINY: {
    src: RAINY_1X,
    srcSet: `${RAINY_1X}, ${RAINY_2X} 2x, ${RAINY_3X} 3x`
  },
  WARM: {
    src: WARM_1X,
    srcSet: `${WARM_1X}, ${WARM_2X} 2x, ${WARM_3X} 3x`
  },
  WINDY: {
    src: WINDY_1X,
    srcSet: `${WINDY_1X}, ${WINDY_2X} 2x, ${WINDY_3X} 3x`
  },
  EVER_CHANGING: {
    src: EVER_CHANGING_1X,
    srcSet: `${EVER_CHANGING_1X}, ${EVER_CHANGING_2X} 2x, ${EVER_CHANGING_3X} 3x`
  },
  SEASONAL_MIX: {
    src: SEASONAL_MIX_1X,
    srcSet: `${SEASONAL_MIX_1X}, ${SEASONAL_MIX_2X} 2x, ${SEASONAL_MIX_3X} 3x`
  },

  DRESS: {
    src: DRESS_1X,
    srcSet: `${DRESS_1X}, ${DRESS_2X} 2x, ${DRESS_3X} 3x`
  },
  BLOUSE_AND_SKIRT: {
    src: BLOUSE_AND_SKIRT_1X,
    srcSet: `${BLOUSE_AND_SKIRT_1X}, ${BLOUSE_AND_SKIRT_2X} 2x, ${BLOUSE_AND_SKIRT_3X} 3x`
  },
  HOODIE_AND_JEANS: {
    src: HOODIE_AND_JEANS_1X,
    srcSet: `${HOODIE_AND_JEANS_1X}, ${HOODIE_AND_JEANS_2X} 2x, ${HOODIE_AND_JEANS_3X} 3x`
  },
  SHIRT_AND_PANTS: {
    src: SHIRT_AND_PANTS_1X,
    srcSet: `${SHIRT_AND_PANTS_1X}, ${SHIRT_AND_PANTS_2X} 2x, ${SHIRT_AND_PANTS_3X} 3x`
  },
  SWEATER_AND_LEGGINGS: {
    src: SWEATER_AND_LEGGINGS_1X,
    srcSet: `${SWEATER_AND_LEGGINGS_1X}, ${SWEATER_AND_LEGGINGS_2X} 2x, ${SWEATER_AND_LEGGINGS_3X} 3x`
  },
  SWEATSHIRT_AND_SWEATPANTS: {
    src: SWEATSHIRT_AND_SWEATPANTS_1X,
    srcSet: `${SWEATSHIRT_AND_SWEATPANTS_1X}, ${SWEATSHIRT_AND_SWEATPANTS_2X} 2x, ${SWEATSHIRT_AND_SWEATPANTS_3X} 3x`
  },

  BAGS: {
    src: BAGS_1X,
    srcSet: `${BAGS_1X}, ${BAGS_2X} 2x, ${BAGS_3X} 3x`
  },
  BELTS: {
    src: BELTS_1X,
    srcSet: `${BELTS_1X}, ${BELTS_2X} 2x, ${BELTS_3X} 3x`
  },
  EYEWEAR: {
    src: EYEWEAR_1X,
    srcSet: `${EYEWEAR_1X}, ${EYEWEAR_2X} 2x, ${EYEWEAR_3X} 3x`
  },
  GLOVES: {
    src: GLOVES_1X,
    srcSet: `${GLOVES_1X}, ${GLOVES_2X} 2x, ${GLOVES_3X} 3x`
  },
  HEADWEAR: {
    src: HEADWEAR_1X,
    srcSet: `${HEADWEAR_1X}, ${HEADWEAR_2X} 2x, ${HEADWEAR_3X} 3x`
  },
  JEWELRY: {
    src: JEWELRY_1X,
    srcSet: `${JEWELRY_1X}, ${JEWELRY_2X} 2x, ${JEWELRY_3X} 3x`
  },
  SCARVES: {
    src: SCARVES_1X,
    srcSet: `${SCARVES_1X}, ${SCARVES_2X} 2x, ${SCARVES_3X} 3x`
  },

  ACCESSORIES: {
    src: ACCESSORIES_1X,
    srcSet: `${ACCESSORIES_1X}, ${ACCESSORIES_2X} 2x, ${ACCESSORIES_3X} 3x`
  },
  ATHLEISURE: {
    src: ATHLEISURE_1X,
    srcSet: `${ATHLEISURE_1X}, ${ATHLEISURE_2X} 2x, ${ATHLEISURE_3X} 3x`
  },
  DRESSY_PIECES: {
    src: DRESSY_PIECES_1X,
    srcSet: `${DRESSY_PIECES_1X}, ${DRESSY_PIECES_2X} 2x, ${DRESSY_PIECES_3X} 3x`
  },
  EVERYDAY_BASICS: {
    src: EVERYDAY_BASICS_1X,
    srcSet: `${EVERYDAY_BASICS_1X}, ${EVERYDAY_BASICS_2X} 2x, ${EVERYDAY_BASICS_3X} 3x`
  },
  FORMAL_ATTIRE: {
    src: FORMAL_ATTIRE_1X,
    srcSet: `${FORMAL_ATTIRE_1X}, ${FORMAL_ATTIRE_2X} 2x, ${FORMAL_ATTIRE_3X} 3x`
  },
  HAVE_EVERYTHING: {
    src: HAVE_EVERYTHING_1X,
    srcSet: `${HAVE_EVERYTHING_1X}, ${HAVE_EVERYTHING_2X} 2x, ${HAVE_EVERYTHING_3X} 3x`
  },
  SEASONAL_WEAR: {
    src: SEASONAL_WEAR_1X,
    srcSet: `${SEASONAL_WEAR_1X}, ${SEASONAL_WEAR_2X} 2x, ${SEASONAL_WEAR_3X} 3x`
  },

  LOOSE: {
    src: LOOSE_1X,
    srcSet: `${LOOSE_1X}, ${LOOSE_2X} 2x, ${LOOSE_3X} 3x`
  },
  OVERSIZED: {
    src: OVERSIZED_1X,
    srcSet: `${OVERSIZED_1X}, ${OVERSIZED_2X} 2x, ${OVERSIZED_3X} 3x`
  },
  REGULAR: {
    src: REGULAR_1X,
    srcSet: `${REGULAR_1X}, ${REGULAR_2X} 2x, ${REGULAR_3X} 3x`
  },
  TAILORED: {
    src: TAILORED_1X,
    srcSet: `${TAILORED_1X}, ${TAILORED_2X} 2x, ${TAILORED_3X} 3x`
  },
  TIGHT: {
    src: TIGHT_1X,
    srcSet: `${TIGHT_1X}, ${TIGHT_2X} 2x, ${TIGHT_3X} 3x`
  },

  MY_ARMS: {
    src: MY_ARMS_1X,
    srcSet: `${MY_ARMS_1X}, ${MY_ARMS_2X} 2x, ${MY_ARMS_3X} 3x`
  },
  MY_BUST: {
    src: MY_BUST_1X,
    srcSet: `${MY_BUST_1X}, ${MY_BUST_2X} 2x, ${MY_BUST_3X} 3x`
  },
  MY_LEGS: {
    src: MY_LEGS_1X,
    srcSet: `${MY_LEGS_1X}, ${MY_LEGS_2X} 2x, ${MY_LEGS_3X} 3x`
  },
  MY_NECK: {
    src: MY_NECK_1X,
    srcSet: `${MY_NECK_1X}, ${MY_NECK_2X} 2x, ${MY_NECK_3X} 3x`
  },
  MY_SHOULDERS: {
    src: MY_SHOULDERS_1X,
    srcSet: `${MY_SHOULDERS_1X}, ${MY_SHOULDERS_2X} 2x, ${MY_SHOULDERS_3X} 3x`
  },
  MY_WAIST: {
    src: MY_WAIST_1X,
    srcSet: `${MY_WAIST_1X}, ${MY_WAIST_2X} 2x, ${MY_WAIST_3X} 3x`
  },

  ...TOP_SIZE_IMAGES,
  ...BOTTOM_SIZE_IMAGES,
};