import type { FC, ReactNode, ComponentProps } from 'react'
import classNames from 'classnames'

import { Text, Title } from '@web-solutions/core/ui-elements';
import type { ImageSource } from '@web-solutions/core/interfaces/images';

import classes from './style.module.scss'

interface HeaderPropsBase {
  title: string | ReactNode;
  subtitle?: ReactNode;
  image?: ImageSource
}

type HeaderProps = Omit<ComponentProps<'div'>, keyof HeaderPropsBase> & HeaderPropsBase

export const Header: FC<HeaderProps> = ({ title, subtitle, image, className, ...props }) => (
  <div className={classNames(classes.wrapper, className)} {...props}>
    <Title id='title' className={classes.title}>
      {title}
    </Title>
    {!!image && <img className={classes.image} src={image.src} srcSet={image.srcSet} alt='book' />}
    {!!subtitle && (
      <Text className={classes.subtitle}>
        {subtitle}
      </Text>
    )}
  </div>
)
