import Localization from '@web-solutions/module-localization';
import { initLocalization as initFR } from '@web-solutions/face-reading/src/localization';
import { initLocalization as initMS } from '@web-solutions/manage-subscription/src/localization';

import { APP_NAME, SUPPORT_EMAIL, SUPPORT_LANGUAGES } from 'src/constants/general';

const resourcesFR = {
  en: require('./face-reading-en.json'),
};

const resourcesManSub = {
  en: require('./locales/en/manage.json'),
  de: require('./locales/de/manage.json'),
  es: require('./locales/es-ES/manage.json'),
  fr: require('./locales/fr/manage.json'),
  'pt-br': require('./locales/pt-BR/manage.json'),
  pt: require('./locales/pt-PT/manage.json'),
  ja: require('./locales/ja/manage.json'),
  ar: require('./locales/ar/manage.json'),
  it: require('./locales/it/manage.json'),
};

export function initLocalization() {
  Localization.init('stylix',
    { appName: APP_NAME, supportEmail: SUPPORT_EMAIL },
    { withAC: true, supportLangs: SUPPORT_LANGUAGES });
  initFR(resourcesFR);
  initMS(resourcesManSub);
};

