import _merge from 'lodash/merge';
import i18next, { TOptions } from 'i18next';
import { Trans, TransProps } from 'react-i18next';
//@ts-ignore
import interpolate from 'interpolate';
import dayjs from 'dayjs';
import resourcesToBackend from 'i18next-resources-to-backend';

import { SUPPORT_LANGUAGES } from '@web-solutions/core/constants/general';

export type LString = string | {
  en: string,
  de?: string,
  es?: string,
  fr?: string,
  pt?: string,
  ja?: string,
  ar?: string,
  'es-mx'?: string,
  'pt-br'?: string,
  ko?: string,
  it?: string,
}

require('dayjs/locale/de')
require('dayjs/locale/es-mx')
require('dayjs/locale/es')
require('dayjs/locale/fr')
require('dayjs/locale/pt-br')
require('dayjs/locale/pt')
require('dayjs/locale/ja')
require('dayjs/locale/ar')
require('dayjs/locale/ko')
require('dayjs/locale/it')

let globalOptions: object | undefined;

const Localization = {
  init: function (
    projectName: string,
    tOptions?: object,
    cfgOptions?: {
      excludeBase?: boolean,
      lng?: string,
      postProcess?: string[],
      excludeCore?: boolean,
      withAC?: boolean,
      withPR?: boolean,
      supportLangs?: Record<string, string>,
      onLoaded?: () => void
    }) {
    globalOptions = tOptions;

    const SUPPORT_LANGS = cfgOptions?.supportLangs || SUPPORT_LANGUAGES;

    let lng = (cfgOptions?.lng || navigator.language || 'en').replace('_', '-').toLowerCase();

    if (!SUPPORT_LANGS[lng]) {
      lng = lng.split('-')[0];
    }
    if (!SUPPORT_LANGS[lng]) {
      lng = 'en';
    }

    dayjs.locale(lng);

    document.documentElement.lang = lng;
    i18next
      .use(resourcesToBackend(async (lng: any) => {
        const files = ['index', 'no_proofread'];
        const language = SUPPORT_LANGS[lng] || 'en';
        let resources = {};

        const transPromises = [] as any;

        if (cfgOptions?.withPR) {
          files.forEach((file) => {
            const translations = import(`@web-solutions/palm-reading/src/localization/${language}/${file}.json`);
            transPromises.push(translations)
          })
        }

        if (cfgOptions?.withAC) {
          const filesAC = ['index_account_management', 'no_proofread'];
          filesAC.forEach((file) => {
            const translations = import(`@web-solutions/account-management/localization/${language}/${file}.json`);
            transPromises.push(translations)
          })
        }

        if (!cfgOptions?.excludeBase) {
          files.forEach((file) => {
            const base = import(`@web-solutions/base-app/localization/locales/${language}/${file}.json`);
            transPromises.push(base)
          })
        }

        if (!cfgOptions?.excludeCore) {
          files.forEach((file) => {
            const translations = import(`./locales/${language}/${file}.json`);
            transPromises.push(translations)
          })
        }

        files.forEach((file) => {
          const translations = import(`@web-solutions/${projectName}/src/localization/locales/${language}/${file}.json`);
          transPromises.push(translations)
        })

        const res = await Promise.allSettled(transPromises)

        res.forEach((item: any) => {
          if (item.status === 'fulfilled') {
            const translations = item.value;
            resources = _merge({}, resources, translations.default || translations);
          }
        })

        return resources;
      }))
      .init<null>({
        lng,
        fallbackLng: 'en',
        lowerCaseLng: true,
        partialBundledLanguages: true,
        react: {
          transEmptyNodeValue: '', // what to return for empty Trans
          transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
          transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b', 's'],
        },
        postProcess: cfgOptions?.postProcess,
      }, (err) => {
        if (err) {
          return console.warn('[i18n ERROR]: transalations not loaded', err)
        }

        cfgOptions?.onLoaded?.()
      });
  },
}

export default Localization;

function processOptions(options?: object) {
  return Object.assign({ context: undefined }, globalOptions, options);
}

export function t(key: string, options?: object & TOptions): string {
  return i18next.t(key, processOptions(options)) as unknown as string;
}

export const pickKey = (key?: LString): string => {
  return typeof key === 'string'
    ? key
    // @ts-ignore
    : key?.[i18next.language] || key?.[i18next.language.split('-')[0]] || '';
}

export function tm(lngs: LString | undefined, defaultKey: string, options?: object & TOptions): string {
  return (
    interpolate(
      pickKey(lngs || undefined),
      processOptions(options)
    )
    ||
    i18next.t(defaultKey, processOptions(options))
  );
}

type TProps = Omit<TransProps<any, any, any, any, any, any>, "i18n" | "i18nKey">


export const T = ({ k, tOptions, ...other }: { k: string, } & TProps) => {
  return (
    <Trans
      i18n={i18next}
      i18nKey={k}
      tOptions={processOptions(tOptions)}
      {...other}
    />
  )
}

export const TM = ({ k, defaultKey = '', tOptions, ...other }: { k?: LString, defaultKey?: string } & TProps) => {
  return (
    <T
      k={interpolate(pickKey(k), processOptions(tOptions)) || defaultKey}
      tOptions={processOptions(tOptions)}
      {...other}
    />
  )
}

