export const BASE_QUIZ = {
  EMAIL: 'EMAIL',
  MAGIC: 'MAGIC',
} as const;

type Keys = keyof typeof BASE_QUIZ;
export type BaseQuizRoute = typeof BASE_QUIZ[Keys];

export const SUBSCRIPTION = {
  PRESUMMARY: '/subscription/presummary',
  MAIN: '/subscription/main',
  SUCCESS: '/subscription/success',
  CREATE_ACCOUNT: '/subscription/create-account',
} as const;


export const SUBSCRIPTION_MAIN_NAMES = {
  PRESUMMARY: 'PRESUMMARY',
  MAIN: 'MAIN',
  SUCCESS: 'SUCCESS',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
} as const;