import type { FC } from 'react'
import classNames from 'classnames';

import { Modal as CoreModal, type ModalProps as CoreModalProps } from '@web-solutions/core/ui-elements'

import classes from './style.module.scss'

interface ModalProps extends CoreModalProps {
  onCrossButtonClick?: () => void;
}

export const Modal: FC<ModalProps> = ({ children, className, overlayClassName, onCrossButtonClick, onCloseModal, ...props }) => (
  <CoreModal
    className={classNames(classes.modalPopup, className)}
    overlayClassName={classNames(classes.modalOverlay, overlayClassName)}
    onCloseModal={onCloseModal}
    {...props}
  >
    {onCrossButtonClick && (
      <button className={classes.crossButton} onClick={onCrossButtonClick} />
    )}
    {children}
  </CoreModal >
)

