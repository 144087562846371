


import type { LString, BaseOfferProductTypes } from '@web-solutions/core/constants/remote-config';
import type { DefaultUpsaleOfferType, BaseUpsalesRemoteContentParams } from '@web-solutions/core/interfaces/upsaleOffers';

export enum OfferProductId {
  STYLING_GUIDE = 'styling_guide',
  WARDROBE = 'wardrobe',

  AGELLES_YOUR_STYLE = 'ageless+your_style',
  MIX_MATCH_STYLE_TIPS = 'mix_match+styling_tips',
  AYS_AND_MMST = 'ageless+your_style+mix_match+styling_tips'
}

export type OfferProductTypes = BaseOfferProductTypes<OfferProductId>;

export type DefaultUpsaleOfferView = 'WITH_IMAGE' | 'WITH_SLIDER' | 'WITH_IMAGE_AND_TITLE';
export type WardrobeUpsaleOfferView = 'WITH_IMAGE' | 'WITH_IMAGE_AND_TITLE';

export type DefaultUpsaleOffer = DefaultUpsaleOfferType<Omit<OfferProductTypes, 'id'>, DefaultUpsaleOfferView> & BaseUpsalesRemoteContentParams
export type WardrobeUpsaleOffer = DefaultUpsaleOfferType<Omit<OfferProductTypes, 'id'>, WardrobeUpsaleOfferView> & BaseUpsalesRemoteContentParams

export type CardsUpsalesOffer = {
  title: LString,
  subtitle: LString,
  submitButton: LString,
  skipButton: LString,
  activeOfferId: OfferProductId | null,
  products: OfferProductTypes[],
}